.blogs-filter__list {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    padding: 0;
    color: $dark-grey;
    font-weight: bold;
    margin-bottom: 0;
}

.blogs-filter__list-item {
    position: relative;
    list-style: none;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    margin: 0 10px;
    opacity: 0.4;
    cursor: pointer;
    transition: opacity 0.2s ease-in-out;

    &--label {
        margin-left: 4px;
    }

    &.active {
        opacity: 1;

        &::after {
            @include iconBlackCircle(14px);
            content: url(/images/icons/check-white.svg);
            color: $white;
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            left: -2px;
            bottom: 0px;
            padding: 0 2px 4px;
        }
    }
}

@media (max-width: $breakpoint-xsmall) {
    .blogs-filter__list-item {
        margin: 0 3px;
    }

    .blogs-filter__list-item--label {
        margin-left: 5px;

    }
}