button{
    outline: none;
}
.btn {
    background: $obd-red;
    color: $white;
    font-size: 12px;
    text-transform: uppercase;
    padding: 15px 30px;
    font-weight: 700;
    border: 2px solid $obd-red;
    transition: all 0.2s ease-in-out;
    min-width: 200px;
    font-family: $body-font;

    &:hover {
        background: transparent;
        color: $obd-red;
        border-color: $obd-red;
        cursor: pointer;
    }

    &--secondary {
        border-color: $black;
        color: $black;
        background: transparent;

        &.no-border {
            border: none;
        }
    }

    &:disabled,
    &[disabled] {
        opacity: 0.4;
        pointer-events: none;
    }
}

.arrow-link {
    color: $black-3;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.08em;
    display: inline-block;
    padding-right: 15px;
    background: url(/images/icons/arrow-right.svg) right center no-repeat;
    position: relative;
    z-index: 0;

    &:hover {
        cursor: pointer;
    }

    &--white-circle {
        @extend .arrow-link;
        background: none;

        &:before {
            content: "";
            display: block;
            background: #fff;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            position: absolute;
            right: -12px;
            top: -8px;
            z-index: -1;
        }

        &:after {
            content: "";
            background: url(/images/icons/arrow-right.svg) right center
                no-repeat;
            display: block;
            position: absolute;
            width: 6px;
            height: 8px;
            top: 3px;
            right: -1px;
        }
    }
}

.double-buttons {
    display: grid;
    grid-template-columns: repeat(2, 200px);
    justify-content: center;
    grid-gap: 15px;

    @media (max-width: $breakpoint-xsmall) {
        grid-template-columns: 1fr;
    }
}

.bottom-button-wrapper {
    padding: 20px 100px;
    display: flex;
    justify-content: space-between;

    @media (max-width: $breakpoint-medium) {
        padding: 20px;
    }

    .btn--back {
        margin-right: 5px;
        min-width: fit-content;
        padding: 15px 20px;
    }
}

.show-more__btn {
    text-align: center;

    > .subtitle {
        position: relative;

        .show-more__icon {
            position: relative;
            width: 20px;
            height: 20px;
            background-color: $gold;
            border-radius: 50%;
            transform: rotate(90deg);
            padding: 5px;
            top: 5px;
            right: -5px;
        }
    }
}
