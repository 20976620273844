.website-feature__section {
    display: flex;
    justify-content: space-evenly;
    margin: 50px 0;
}

.website-feature {
    width: calc(33% - 30px);
    text-align: center;
    max-width: 350px;
}

@media (max-width: $breakpoint-small) {
    .website-feature__section {
        flex-wrap: wrap;
    }
    .website-feature {
        width: calc(50% - 30px);
        margin: 20px 0;
    }
}

@media (max-width: $breakpoint-xsmall) {
    .website-feature {
        width: 90%;
    }
}
