.react-datepicker-wrapper {
    width: 100%;

    input {
        width: 100%;
        background: #faf8f5;
        box-shadow: inset 0 2px 7px 0 rgba(91, 55, 48, 0.1);
        border: none;
        font-size: large;
        padding-left: 20px;
        width: 100%;
        letter-spacing: 0;
        height: 56px;
    }
}
