/* ----- PUT ALL YOUR AMAZING VARIABLES IN HERE ----- */
$title-font: "Playfair Display",
serif;
$body-font: "Lato",
sans-serif;

$background_color: #f8f4f1;

/* Colors */
$obd-red: #e21d41;
$black: #6f6e6e;
$black-2: #3b372a;
$black-3: #33312b;
$white: #fff;
$white-bg: #fdfdfd;
$off-white: #fae9ec;
$off-white-2: #cfc6ca;
$off-white-3: #aba4a4;
$border-light: #ededed;
$beige: #faf8f5;
$beige-dark: #e8e5ca;
$gold: #ddd7a5;
$dark-grey: #4f4e4e;
$light-grey: #aba8a1;
$beige-bg: #f8f4f1;
$beige-light-bg: #F7F4F0;
$blue-bg: #f5fafd;
$transparent: rgba(255, 255, 255, 0.001);

/* Breakpoints */
$breakpoint-large: 1200px;
$breakpoint-medium: 992px;
$breakpoint-small: 768px;
$breakpoint-xsmall: 576px;
$breakpoint-xxsmall: 375px;