.page--account {
    .account__wrapper {
        background: $beige-light-bg;
        max-width: 1540px;
        padding-bottom: 50px;

        @media (max-width: $breakpoint-small) {
            padding: 0 1rem 50px;
        }

        .circle-title__subtitle {
            width: fit-content;
            margin: 0 auto;

            &:before {
                content: "";
                position: absolute;
                top: 10px;
                left: -60px;
                border-top: 2px solid #ddd7a5;
                width: 40px;
            }

            &:after {
                content: "";
                position: absolute;
                top: 10px;
                right: -60px;
                border-top: 2px solid #ddd7a5;
                width: 40px;
            }
        }
    }

    .account-page__form-container {
        .account-forms {
            width: 360px;
            margin: 0 auto;
            position: relative;

            @media (max-width: $breakpoint-small) {
                width: 100%;
            }

            .text-input {
                min-height: 65px;
            }

            .text-input__input::placeholder {
                font-size: 15px;
                color: $light-grey;
                font-weight: 400;
                font-style: italic;
                font-family: $body-font;
            }

            .text-input__input::-webkit-input-placeholder {
                font-size: 15px;
                color: $light-grey;
                font-weight: 400;
                font-style: italic;
                font-family: $body-font;
            }

            .text-input__input::-moz-placeholder {
                font-size: 15px;
                color: $light-grey;
                font-weight: 400;
                font-style: italic;
                font-family: $body-font;
            }

            .guest-account__wrapper {
                border-top: 1px solid #ededed;
                margin-top: 30px;
                padding-top: 15px;
                color: $dark-grey;

                .title {
                    font-family: $title-font;
                    font-size: 19px;
                    letter-spacing: -0.5px;
                    cursor: pointer;
                    color: $dark-grey;
                }

                .guest-account__svg--arrow-red-bg {
                    position: relative;
                    top: 2px;
                    left: 2px;
                }

                .description {
                    font-style: italic;
                    font-family: $body-font;
                    font-weight: 300;
                    font-size: 15px;
                    letter-spacing: 0.18px;
                    line-height: 31px;
                }
            }
        }

        .btn {
            width: 100%;
        }
    }

    .account__title{
        font-size: 38px;
        margin: 0 0 -10px;
        line-height: 1;
    }
    .account__para{
        font-size: 19px;
        font-style: italic;
        font-weight: 300;
    }

    .account__tabs {
        list-style-type: none;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        margin: 0 auto 1rem;
        white-space: nowrap;
        cursor: pointer;

        li {
            margin: 10px;
            opacity: 0.4;
            font-family: $title-font;
            font-size: 19px;

            &.is-active {
                opacity: 1;
                color: #4f4e4e;
            }
        }
    }

    .card {
        box-shadow: 0 4px 30px 0 rgba(207, 198, 202, 0.3);
        padding-top: 1rem;
        padding-bottom: 3rem;
    }
}
