.feature-section {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 1rem 0rem 1rem;
    flex-wrap: wrap;
    position: relative;

    &.style-one {
        background: $beige-bg;
    }

    &:nth-child(2n) {
        flex-direction: row-reverse;
        padding-bottom: 30px;
    }
}

.feature-section__text-wrap {
    text-align: center;
    position: relative;
    max-width: 550px;
    width: 45%;

    &:before {
        content: "";
        width: 273px;
        height: 273px;
        background: $white;
        border-radius: 273px;
        position: absolute;
        top: 10px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
    }

    &.coming-soon {
        &::after {
            content: url(/images/coming-soon-text.png);
            position: absolute;
            top: 25px;
            z-index: 2;
            left: 0;
            right: 0;
        }
    }
}

.feature-section.style-two {
    .feature-section__text-wrap {
        margin: 0 -75px;
    }

    .feature-section__image {
        width: 84%;
        position: relative;
        margin: 0 -200px;
        z-index: 1;
        max-width: 850px;

        & > img {
            border: 1px solid $white;
        }

        &:after {
            @extend .gradient;
            background: linear-gradient(
                    90deg,
                    rgba(255, 255, 255, 1) 0%,
                    $transparent,
                    $transparent 60%,
                    white 85%
                ),
                linear-gradient(
                    white,
                    $transparent,
                    $transparent 60%,
                    white 86%
                );
        }
    }
}

.page--home {
    .feature-section.style-two {
        &:nth-of-type(odd) {
            .feature-section__text-wrap {
                margin-right: -100px;
            }

            .feature-section__image {
                margin-right: -345px;
                margin-left: 110px;
            }
        }

        &:nth-of-type(even) {
            .feature-section__text-wrap {
                margin-left: -100px;
            }

            .feature-section__image {
                margin-left: -200px;
                margin-right: 0;
            }
        }
    }
}

.feature-section__text-wrap-inner {
    position: relative;
    z-index: 4;
}

.feature-section__subtitle {
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.08em;
}

.feature-section__title {
    font-size: 2rem;
    margin-top: 10px;
    margin-bottom: 5px;

    &:after {
        margin-top: 25px;
    }
}

.feature-section__text {
    font-size: 19px;
    font-style: italic;
    margin-bottom: 30px;
}

.feature-section__image {
    width: 50%;
    position: relative;
    margin: 0 -25px;
}

.page--home > .container > .feature-section.style-one:first-child {
    padding-top: 500px;
    margin-top: -360px;
}

@media (max-width: $breakpoint-medium) {
    .feature-section__title {
        font-size: 1.7rem;
    }

    .feature-section__text {
        font-size: 15px;
    }

    .page--home {
        .feature-section.style-two {
            &:nth-of-type(odd) > .feature-section__image {
                margin-left: -50px;
            }

            &:nth-of-type(even) > .feature-section__image {
                margin-right: -50px;
            }
        }
    }
}

@media (max-width: $breakpoint-small) {
    .feature-section__text-wrap,
    .feature-section__image {
        min-width: 320px;
        width: 90%;
    }

    .feature-section__text-wrap {
        &:before {
            content: "";
            width: 212px;
            height: 212px;
        }

        &.coming-soon::after {
            top: 15px;
        }
    }

    .feature-section.style-two {
        flex-direction: column-reverse;

        & > .feature-section__text-wrap {
            margin-top: -75px;
        }

        .feature-section__image {
            margin: 0 !important;

            &:after {
                background: linear-gradient(
                        90deg,
                        rgba(255, 255, 255, 1) 0%,
                        $transparent,
                        $transparent 90%,
                        white 100%
                    ),
                    linear-gradient(
                        white,
                        $transparent,
                        $transparent 60%,
                        white 86%
                    );
            }
        }
    }
}

@media (max-width: $breakpoint-xsmall) {
    .feature-section.style-one {
        overflow: hidden;

        .feature-section__image {
            height: 450px;

            & > img {
                position: absolute;
                top: 0;
                left: -85px;
                min-width: 600px;
            }
        }
    }

    .page--home > .container > .feature-section.style-one:first-child {
        padding-top: 400px;
        margin-top: -330px;
    }

    .feature-section.style-one:nth-child(even) {
        & > .feature-section__image > img {
            right: -85px;
            left: unset;
        }
    }

    .feature-section__text-wrap-inner {
        max-width: 100%;
    }

    .feature-section__text-wrap {
        margin-left: auto !important;
        margin-right: auto !important;
    }
}
