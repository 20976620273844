.circle-title {
    text-align: center;
    max-width: 700px;
    position: relative;

    &:before {
        content: "";
        display: block;
        background: $white;
        width: 221px;
        height: 221px;
        border-radius: 221px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
        top: -30px;
    }
}

.circle-title.circle-title--small {
    @extend .circle-title;

    &:before {
        width: 162px;
        height: 162px;
    }
}

.circle-title__subtitle {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.07em;
    position: relative;
    z-index: 2;
}

.circle-title__title {
    font-size: 38px;
    line-height: 1;
    position: relative;
    z-index: 2;
    margin: 10px 0;

    &.line-under:after {
        margin-top: 25px;
    }
}

.circle-title__secondary-title {
    @extend .circle-title__title;
}

@media (max-width: $breakpoint-medium) {
    .circle-title__title {
        font-size: 2.5rem;
    }
}

@media (max-width: $breakpoint-small) {
    .circle-title__title {
        font-size: 1.8rem;
    }
}
