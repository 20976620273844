.specialists__main {
    display: flex;
    background: $beige-bg;
    padding-top: 350px;
    margin-top: -300px;
    position: relative;
    min-height: 381px;
}

.page-specialists__banner {
    position: relative;

    .vendors-ref {
        position: absolute;
        bottom: 200px;

        @media (max-width: $breakpoint-xsmall) {
            bottom: 50px;
        }
    }
}

.specialists__sidebar {
    background: $white;
    padding: 50px 50px 50px 130px;
    position: relative;
}

.specialists__main-img-bracelet {
    position: absolute;
    top: 0;
    transform: translateY(-100%);
    left: 0;
}

.specialists__cats-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 153px;
    position: -webkit-sticky;
    /* Safari */
    position: sticky;
    top: 110px;

    li {
        margin-bottom: 10px;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 11px;
        opacity: 0.4;
        transition: opacity 0.2s ease-in-out;
        cursor: pointer;
        white-space: nowrap;
        position: relative;

        svg {
            display: inline-block;
            vertical-align: middle;
            width: 20px;
            height: auto;
            margin-right: 15px;
        }

        &.active {
            opacity: 1;

            &::after {
                @include iconBlackCircle(10px);
                content: url(/images/icons/check-white.svg);
                color: $white;
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                left: -3px;
                bottom: -2px;
                padding: 2px;
            }
        }
    }
}

.specialists__list {
    display: flex;
    flex-wrap: wrap;
    max-width: 1100px;
    min-height: 381px;
    padding: 0 15px;
    width: 100%;
    position: relative;

    .vendors-ref {
        position: absolute;
        top: -150px;
    }
}

.specialists__pagination {
    display: flex;
    justify-content: center;
}

.specialists__filter,
.filter-modal__header {
    display: none;
}

.specialists__digital-auntie {
    margin-top: 50px;

    &>.feature-section {
        flex-direction: row-reverse;
        padding: 0;
        z-index: 0;

        .feature-section__image {
            width: 50%;
            height: 384px;

            &>img {
                height: inherit;
                border: none;
            }

            &:after {
                left: -3px;
                width: 102%;
                background: linear-gradient(90deg,
                        $beige-bg,
                        $transparent,
                        $beige-bg 85%);
            }
        }

        .feature-section__text-wrap {
            height: 384px;

            &:before {
                width: 150px;
                height: 150px;
                z-index: 2;
                top: 50px;
            }
        }
    }
}

@media (max-width: $breakpoint-medium) {
    .page--specialists.filter-modal-open:after {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #000;
        opacity: 0.85;
        top: 0;
        left: 0;
        content: "";
        z-index: 1;
    }

    .specialists__sidebar,
    .specialists__main-img-bracelet {
        display: none;
    }

    .specialists__sidebar.filter-modal-open {
        display: flex;
        position: fixed;
        flex-direction: column;
        z-index: 2;
        align-self: center;
        padding: 1rem;
        top: 15%;
        width: 55%;
        height: 80%;

        .filter-modal__header {
            display: block;
            position: relative;
            width: 100%;
            padding: 0 1rem;
            text-align: center;
        }

        .filter__button--close {
            display: flex;
            justify-content: flex-end;
            margin-bottom: 2rem;
            margin-top: 1rem;
        }
    }

    .specialists__filter {
        display: block;
        max-width: 190px;

        &>.specialists__filter-button {
            text-transform: uppercase;
            display: flex;
            padding: 1rem 2rem;
            background: $white;
            border: none;
            width: 190px;
        }

        &:after {
            position: absolute;
            width: inherit;
            height: 100%;
            background: $white;
            content: "";
        }
    }

    .specialists__main {
        flex-direction: column;
        align-items: flex-end;
        position: relative;
    }

    .specialists__cats-list {
        margin: 0 auto;
        line-height: 30px;
    }
}

@media (max-width: $breakpoint-small) {
    .specialists__digital-auntie {
        &>.feature-section {
            flex-direction: column;

            .feature-section__image {
                margin-top: -50px;
                width: 70%;
                height: 300px;
                margin: 0;

                &>img {
                    height: inherit;
                    border: none;
                    object-fit: cover;
                }

                &:after {
                    top: -3px;
                    height: calc(100% + 3px);
                    background: linear-gradient($beige-bg, $transparent 25%),
                        linear-gradient(90deg,
                            $beige-bg,
                            $transparent,
                            $beige-bg);
                }
            }

            .feature-section__text-wrap {
                height: 300px;
                margin-bottom: -50px;
                margin: 0;

                &:before {
                    width: 181px;
                    height: 181px;
                    z-index: 2;
                    top: 50px;
                }
            }
        }
    }
}

@media (max-width: $breakpoint-xsmall) {
    .specialists__list {
        padding: 0;
        justify-content: center;
    }

    .specialists__cats-list {
        line-height: 27px;
    }

    .filter-modal__header {
        h2 {
            margin-top: 0;
        }
    }

    .specialists__sidebar.filter-modal-open {
        top: 7rem;
        width: 85%;
        height: 75%;

        .filter__button--close {
            margin-bottom: 0;
        }
    }

    .specialists__digital-auntie {
        margin-top: 10px;

        &>.feature-section {
            .feature-section__image {
                width: 130px;

                &:after {
                    left: 0;
                    width: 100%;
                }
            }

            &>.feature-section__text-wrap>.feature-section__text-wrap-inner>.card {
                padding: 50px 25px;
            }
        }
    }
}