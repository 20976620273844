

div[class^="close__button-"]{
  width: 25px;
  height: 3px;
  background-color: #3f3f3f;
  margin: 8px 0;
}

/* Rotate first bar */
.close__button-bar1 {
  -webkit-transform: rotate(-45deg) translate(-2px, 1px) ;
  transform: rotate(-45deg) translate(-2px, 1px) ;
}

/* Rotate last bar */
.close__button-bar2 {
  -webkit-transform: rotate(45deg) translate(-7px, -6px) ;
  transform: rotate(45deg) translate(-6px, -7px) ;
}
