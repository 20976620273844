// old google fonts
// @import url("https://fonts.googleapis.com/css?family=Lato:300,400,700|Playfair+Display:400,700");

@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Playfair+Display:ital,wght@0,400;0,700;1,400;1,700&display=swap");

$title-font: "Playfair Display", serif;
$body-font: "Lato", sans-serif;

body {
    font-family: $body-font;
    color: $dark-grey;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 300;
    line-height: 1.5;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $title-font;
    font-weight: 400;
    font-size: 19px;
    letter-spacing: -0.03em;
    line-height: 1.2;
}

.title--lines {
    font-size: 13px;
    font-family: $body-font;
    text-transform: uppercase;
    color: $black-3;
    text-align: center;
    font-weight: 700;
    letter-spacing: 0.08em;
    position: relative;

    &:before {
        content: "";
        position: absolute;
        top: 6px;
        left: 0;
        right: 0;
        border-top: 2px solid $black-3;
        z-index: 1;
    }

    span {
        display: inline-block;
        background: $beige-bg;
        position: relative;
        z-index: 2;
        padding: 0 10px;
    }
}

a {
    text-decoration: none;
    color: $obd-red;

    &.no-style {
        color: $dark-grey;
    }
}

.italics-text {
    font-size: 19px;
    font-style: italic;
    margin-bottom: 30px;
}

.line-under {
    position: relative;

    &:after {
        content: "";
        width: 131px;
        height: 0;
        border-top: 2px solid $gold;
        display: block;
        margin: 10px auto 0;
    }

    &--full-width {
        @extend .line-under;

        &:after {
            width: 100%;
        }
    }

    &--large {
        @extend .line-under;

        &:after {
            width: 200px;
        }
    }
}

.subtitle {
    font-family: $body-font;
    font-size: 0.7rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.copy-to-clipboard {
    font-family: $body-font;
    font-size: 12px;
    color: $obd-red;
    text-decoration: underline;
    font-weight: 400;
    cursor: pointer;
}
input{
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0;
}