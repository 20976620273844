/**
This is just for prototyping - this should be removed and not used!
 */

.proposal-landing__container {
    .button-wrapper {
        background: #fdfdfd;
        border: 1px solid #ededed;
        box-shadow: 0 4px 30px 0 rgba(207, 198, 202, 0.3);
        padding: 1rem 8rem;
        text-align: right;

        @media (max-width: $breakpoint-medium) {
            text-align: center;
        }

        button:disabled,
        button[disabled] {
            opacity: 0.4;
            pointer-events: none;
        }
    }
}

.proposal-landing {
    background-color: $beige-bg;
    padding: 3rem;

    @media (max-width: $breakpoint-small) {
        padding: 3rem 3rem;
    }

    @media (max-width: $breakpoint-xsmall) {
        padding: 3rem 1rem;
    }

    .circle-title {
        margin: 0 auto;
        max-width: 825px;

        &__subtitle {
            width: fit-content;
            margin: 0 auto;
            letter-spacing: 0.18em;

            &:before {
                content: "";
                position: absolute;
                top: 10px;
                left: -60px;
                border-top: 2px solid #ddd7a5;
                width: 40px;
            }

            &:after {
                content: "";
                position: absolute;
                top: 10px;
                right: -60px;
                border-top: 2px solid #ddd7a5;
                width: 40px;
            }
        }

        &__title {
            font-size: 38px;
            letter-spacing: -1.5px;
            line-height: 46px;
            @media (max-width: $breakpoint-xsmall) {
                font-size: 26px;
                line-height: 1.2;
            }
        }

    }

    .description {
        font-style: italic;
        max-width: 825px;
        margin: 0 auto 35px;
        text-align: center;
        font-size: 19px;
        color: #4f4e4e;
        line-height: 29px;
        position: relative;
        z-index: 1;
    }
}

.da-landing-cat-select {
    display: flex;
    flex-wrap: wrap;
    grid-template-columns: repeat(3, minmax(auto, 312px));
    max-width: 980px;
    margin: 0 auto;
    justify-content: center;

    @media (max-width: $breakpoint-small) {
        justify-content: space-around;
        grid-template-columns: repeat(2, 1fr);
    }
}
.da-landing__cat-item {
    flex: 0 0 calc(100% / 3 - 24px);
    max-width: 312px;
    cursor: pointer;
    padding: 20px 0 30px;
    height: 325px;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: relative;
    background-size: contain;
    background-position: top center;
    box-shadow: 0 4px 20px 0 rgba(207,198,202,0.30);
    margin: 12px;

    @extend .gradient;

    @media (max-width: $breakpoint-medium) {
        height: 207px;
        background-size: contain;
        padding-bottom: 10px;
    }

    @media (max-width: $breakpoint-xsmall) {

        background-size: cover;
        height: 207px;
        padding: 10px 25px 20px;
        flex: 0 0 calc(100% / 2 - 12px);
        margin: 6px;
        background-size: 164px auto;
    }

    &::before {
        @include iconBlackCircle(57px);
        content: url(/images/icons/check-gold--large.svg);
        background: $dark-grey;
        position: absolute;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 30%;
        opacity: 0;
        transition: all .4s ease-in-out;

        @media (max-width: $breakpoint-xsmall) {
            top: 33%;
            @include iconBlackCircle(40px);
            padding-top: 7px;
            height: 35px;
        }
    }

    &:after {
        background-image: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0) 0%,
            #e0dcbc 100%
        ),
        linear-gradient(
            180deg,
            rgba(224, 220, 188, 0.1) 70%,
            rgba(224, 220, 188, 1) 80%
        );
        opacity: 0;
        outline: 2px solid #f2eeda;
        transition: all .4s ease-in-out;
        outline-offset: -5px;

        @media (max-width: $breakpoint-xsmall) {
            outline: 1px solid #f2eeda;
        }
    }

    &.selected {

        &::before {
           opacity: 1;
        }

        &:after {
            opacity: 1;
        }
    }
}

.da-landing__cat-item-wrap-container {
    width: 100%;

    &:after {
        @extend .gradient;
        background-image: linear-gradient(
                180deg,
                rgba(255, 255, 255, 0) 40%,
                #ffffff 100%
            ),
            linear-gradient(
                180deg,
                rgba(253, 252, 251, 0.1) 60%,
                rgba(250, 248, 245, 1) 80%
            );
            opacity: 1;
            transition: all .4s ease-in-out;
            outline: 1px solid $gold;
            outline-offset: -5px;

            @media(max-width: $breakpoint-xsmall){
                background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 32%, #ffffff 60%), linear-gradient(180deg, rgba(253, 252, 251, 0.1) 86%, #faf8f5 100%);
            }

    }

    // .da-landing__cat-item.selected &:after{
    //         opacity: 0;
    // }
}

.da-landing__cat-item-text-wrap {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: $title-font;
    font-size: 20px;
    color: $dark-grey;
    line-height: 1.2;
    text-align: center;
    width: 100%;

    @media(max-width: $breakpoint-xsmall){
        font-size: 16px;
    }


}
