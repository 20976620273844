.video-thumb-player {
    margin: 2.5px 0;
    width: inherit;
    height: inherit;

    &:hover {
        cursor: pointer;
    }
}

.video-thumb__modal {
    position: absolute;
    opacity: 0;
    visibility: hidden;
}

.video-thumb__preview {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: inherit;
    width: inherit;

    & > .video-thumb__icon-container {
        @extend .video-block__icon-container;
        position: absolute;
        width: 40px;
        height: 40px;
    }

    & > .video-thumb-player__icon {
        width: 30%;
        height: 35%;
    }

    & > img {
        height: inherit;
        width: inherit;
    }
}

.video-thumb__modal.video-thumb--playing {
    opacity: 1;
    visibility: visible;
    width: 650px;
    height: 360px;

    &:after {
        position: fixed;
        width: 200vh;
        height: 100vh;
        background-color: #000;
        opacity: 0.85;
        top: 0;
        left: 0;
        content: "";
        z-index: 10;
    }
}

.video-thumb__player {
    position: fixed;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 99;
    top: 25%;

    & > .play-icon:after {
        opacity: 1;
    }
}

@media (max-width: $breakpoint-small) {
    .video-thumb__modal.video-thumb--playing {
        width: 400px;
        height: 220px;
    }
}

@media (max-width: $breakpoint-xsmall) {
    .video-thumb__modal.video-thumb--playing {
        width: 300px;
        height: 160px;
    }
}

@media (max-width: $breakpoint-xxsmall) {
    .video-thumb__modal.video-thumb--playing {
        width: 250px;
        height: 130px;
    }
}
