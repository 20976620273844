.dashboard-container {
    display: flex;
    align-items: stretch;
    min-height: 85vh;
    background-color: $beige-bg;

    @media (max-width: $breakpoint-medium) {
        flex-direction: column;
    }
}

.dashboard__sidebar {
    background: $obd-red;
    color: $white;
    font-family: $body-font;
    font-size: 12px;
    letter-spacing: 1.3px;
    font-weight: bold;
    text-transform: uppercase;
    position: relative;
    flex-basis: 270px;
    flex-shrink: 0;

    @media (max-width: $breakpoint-medium) {
        overflow-x: scroll;
        flex-basis: auto;
    }

    ul {
        list-style-type: none;
        padding: 0;

        @media (max-width: $breakpoint-medium) {
            display: flex;
            justify-content: space-around;
            margin-top: 0;
        }

        @media (max-width: $breakpoint-xsmall) {
            justify-content: space-between;
        }

        li {
            margin-bottom: 20px;
            padding: 0 30px 0 54px;

            &.active {
                background-image: linear-gradient(
                    270deg,
                    #e21d41 0%,
                    #cc1a3b 100%
                );
            }

            @media (max-width: $breakpoint-medium) {
                margin: 0;
                padding: 20px 10px 10px;

                &.active {
                    background-image: linear-gradient(
                        0deg,
                        #e21d41 0%,
                        #cc1a3b 100%
                    );
                }
            }

            a,
            .signout-button {
                @media (max-width: $breakpoint-medium) {
                    display: flex;
                    flex-direction: column;
                    width: 90px;
                    text-align: center;

                    p {
                        margin-bottom: 0;
                        font-size: 10px;
                    }
                }
            }

            svg {
                margin-right: 10px;

                @media (max-width: $breakpoint-medium) {
                    margin-right: 0;
                }
            }

            &.coming-soon {
                opacity: 0.5;
                pointer-events: none;

                p::after {
                    content: url(/images/icons/coming-soon.svg);
                    position: relative;
                    left: 5px;
                    top: 2px;

                    @media (max-width: $breakpoint-medium) {
                        left: 0;
                    }
                }
            }
        }
    }

    a {
        color: $white;
        display: flex;
        align-items: center;
    }

    .signout-button {
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-top: 10rem;

        @media (max-width: $breakpoint-medium) {
            margin-top: 0;
        }
    }
}

.dashboard__container-inner {
    flex-grow: 1;
    padding: 50px;
    max-width: 1300px;
    margin: 0 auto;
    background: $beige-bg;

    .title {
        font-size: 30px;
        color: $dark-grey;
        letter-spacing: -1px;
        line-height: 37px;

        @media (max-width: $breakpoint-xsmall) {
            font-size: 24px;
        }
    }

    .description {
        font-style: italic;
        font-size: 19px;
        color: $dark-grey;
        letter-spacing: 0.2px;
        line-height: 29px;

        @media (max-width: $breakpoint-xsmall) {
            font-size: 17px;
        }
    }

    @media (max-width: $breakpoint-small) {
        padding: 20px;
        margin: unset;
    }
}

.dashboard-proposal__inner-wrapper {
    background-color: $white-bg;
    padding: 80px 90px;
    position: relative;
    box-shadow: 0 4px 30px 0 rgba(207,198,202,0.30);


    @media (max-width: $breakpoint-small) {
        padding: 40px;
        padding-bottom: 80px;
    }
}

.dashboard-proposal__top-row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: $breakpoint-xsmall) {
        text-align: center;
        .title {
            font-size: 20px;

            letter-spacing: -0.67px;
            line-height: 23px;
        }
    }
}

.dashboard__proposal__items {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: 30px;
    padding-left: 0;

    @media (max-width: $breakpoint-small) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: $breakpoint-xsmall) {
        grid-template-columns: 1fr;
    }
}

.dashboard__proposal-item {
    list-style: none;
    display: flex;
    align-items: center;
    position: relative;
    color: $off-white-3;

    &.selected {
        font-weight: 700;
        color: $dark-grey;

        &::after {
            @include iconBlackCircle(10px);
            content: url(/images/icons/check-white.svg);
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            left: -5px;
            bottom: 0px;
            padding: 0 2px 4px;
        }
    }

    svg {
        margin-right: 5px;
    }
}

.dashboard-feedback-page {
    background-color: $white;
    padding: 75px 95px;
    box-shadow: 0 4px 30px 0 rgba(207,198,202,0.30);

    @media (max-width: $breakpoint-medium) {
        padding: 48px 60px;
    }

    @media (max-width: $breakpoint-xsmall) {
        text-align: center;
        padding: 25px;
    }

    &__form {
        display: flex;
        flex-direction: column;
        align-items: center;

        textarea {
            height: 230px;
            background-color: $beige;
            border: none;
            box-shadow: inset 0 2px 7px 0 rgba(91, 55, 48, 0.1);
            font-family: $body-font;
            font-style: italic;
            font-size: 16px;
            padding: 20px;
            margin-bottom: 20px;
            width: 100%;
            resize: none;

            &:focus {
                outline: none;
            }
        }

        .btn {
            max-width: 200px;

            @media (max-width: $breakpoint-xsmall) {
                max-width: none;
                width: 100%;
            }
        }
    }
}

.dashboard-load-page {
    height: 85vh;
    background: $beige-bg;
    opacity: 1;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    transition: all 0.5s ease-out;


    .dashboard-load__text-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        text-align: center;
        > * {
            position: relative;
            z-index: 1;
        }

        &:after {
            content: "";
            background: $white;
            width: 221px;
            height: 221px;
            border-radius: 221px;
            position: absolute;
            left: 50%;
            transform: translate(-50%, -20px);
        }
    }

    .dashboard-load__text--first-name,
    .dashboard-load__text--partner-name {
        font-size: 60px;
        color: $dark-grey;
        letter-spacing: -2px;
        line-height: 61px;
        margin: 1rem;
        white-space: break-spaces;

        @media (max-width: $breakpoint-xsmall) {
            font-size: 40px;
        }
    }
    .giant-ampersand {
        font-family: $title-font;
        font-size: 77px;
        color: #e21d41;
        letter-spacing: -3.04px;
        line-height: 46px;
    }

    .loading-text {
        font-size: 60px;
        color: $dark-grey;
        letter-spacing: -2px;
        line-height: 61px;
        margin: 0;
        transform: translateY(-20px);
        white-space: break-spaces;

        @media (max-width: $breakpoint-xsmall) {
            font-size: 40px;
        }
    }

    &.inactive {
        visibility: hidden;
        opacity: 0;
    }
}

@keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
