* {
    box-sizing: border-box;
}

body {
    overflow-x: hidden;
}

#root {
    position: relative;
}

.router--main {
    position: relative;
    z-index: 1;
    min-height: 85vh;
}

.container {
    max-width: 1540px;
    padding: 0 20px;
    margin: 0 auto;

    &--large {
        @extend .container;
        max-width: 1200px;
    }

    &--medium {
        @extend .container;
        max-width: 800px;
    }

    &--small {
        @extend .container;
        max-width: 600px;
    }
}

img {
    max-width: 100%;
    height: auto;
}

div[class^="page--"] {
    position: relative;
}

textarea {
    height: 230px;
    background-color: $beige-bg;
    border: none;
    box-shadow: inset 0 2px 7px 0 rgba(91, 55, 48, 0.1);
    font-family: $body-font;
    font-style: italic;
    color: $dark-grey;
    font-size: 15px;
    padding: 20px;
    margin-bottom: 20px;
    width: 100%;
    resize: none;

    &:focus {
        outline: none;
    }
}

.beige-bg {
    background: $beige-bg;

    &--large:after {
        max-width: 1500px;
    }
}

.border-inside {
    outline: 2px solid #f2eeda;
    outline-offset: -10px;
}

.bg--white {
    background: $white !important;
}

.shadow {
    box-shadow: 0 4px 30px 0 rgba(207, 198, 202, 0.3);

    &--dark {
        box-shadow: 0 4px 20px 0 rgba(207, 198, 202, 0.3);
    }

    &--around {
        box-shadow: 0 2px 26px 0 rgba(91, 55, 48, 0.08);
    }
}

.center-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: $breakpoint-xsmall) {
    .container {
        padding: 0;
    }

    .router--main {
        min-height: 52vh;
    }
}
