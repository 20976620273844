.account-text-input.invalid {
    .text-input__icon {
        background-color: $obd-red;
        border: 2px solid $obd-red;
        border-right: 0;
    }

    .text-input__input {
        border: 2px solid $obd-red;
        color: $obd-red;
        background-color: #ffeded;
    }

    svg g {
        fill: $white;
    }
}

.validation-message__wrapper {
    display: flex;
    margin: 5px 0;
    font-family: $body-font;
    font-size: 14px;
    color: #4f4e4e;
    letter-spacing: 0.16px;
    font-style: italic;
    font-weight: 400;
    align-items: center;

    .validation-message {
        margin-left: 5px;
    }
}

.forgot-password__button-wrapper {
    text-align: end;
}

.forgot-password__button {
    font-family: $body-font;
    font-size: 12px;
    color: $dark-grey;
    text-decoration: underline;
    font-weight: 400;
}

.create-account-terms__wrapper {
    font-family: $body-font;
    font-size: 12px;
    color: $dark-grey;
    white-space: nowrap;
    font-weight: 400;
    position: relative;
    left: -5px;
    margin: 20px 0 12px;

    .create-account-terms {
        color: inherit;
        text-decoration: underline;
    }

    @media (max-width: $breakpoint-small) {
        white-space: unset;
    }
}

.error-message {
    color: $obd-red;
    position: absolute;
    left: 0;
    right: 0;
    margin-top: 10px;
}

.account-submit__btn {
    margin-top: 0.5rem;
    font-family: $body-font;
    font-weight: 700;
}
