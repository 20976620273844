.page--article-inner {
    background-size: 100% auto;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    position: relative;
    background-color: $beige-bg;
    padding-top: 300px;

    &:before {
        content: "";
        position: absolute;
        top: 360px;
        left: 0;
        right: 0;
        bottom: 0;
        background: url(/images/clouds-article.png) top center no-repeat;
        background-size: 100% auto;
        z-index: 1;
    }
}

.page--article-main {
    max-width: 870px;
    background: $white;
    padding: 42px 60px 80px;
    position: relative;
    z-index: 2;
    margin-right: 32px;

    .article-meta {
        * {
            display: inline-block;
            vertical-align: middle;
            color: #ababa8;
            font-size: 10px;
            font-weight: 700;
            text-transform: uppercase;
            letter-spacing: 0.08em;
        }

        svg {
            width: 20px;
            height: 20px;
            margin-right: 5px;
        }

        .article-category {
            margin-right: 7px;

            &:after {
                content: "";
                width: 4px;
                height: 4px;
                border-radius: 4px;
                background: #ababa8;
                display: inline-block;
                margin-left: 7px;
            }
        }
    }

    .page--article-title {
        font-size: 48px;
    }
}

.article-page__field-paragraphs {
    display: flex;
    flex-direction: column;

    .field-paragraph__container {
        &>img {
            margin-bottom: 30px;
        }

        .field-paragraph__main {
            display: flex;
            margin-bottom: 40px;
        }

        .field-paragraph__step {
            font-size: 4rem;
            color: $gold;
            margin: 0;
            margin-right: 20px;
            line-height: 1;
        }

        .field-paragraph__text-wrap {
            padding-right: 20px;
        }
    }
}

.page--article-sidebar {
    position: relative;
    z-index: 2;
    padding-top: 250px;
    margin-right: 1rem;

    .title--lines {
        span {
            background: none;
        }

        &:before {
            width: 20%;
        }

        &:after {
            content: "";
            position: absolute;
            top: 6px;
            right: 0;
            border-top: 2px solid #33312b;
            z-index: 1;
            width: 20%;
        }
    }
}

.article-page__sidebar-send-enquiry {
    width: 200px;
    display: flex;
    justify-content: center;
    margin: 0 auto;

    .card-text-link {
        &:before {
            z-index: -1;
            width: 140px;
            height: 140px;
            top: 40px;
        }

        .card-text-link__title {
            font-size: 23px;
        }

        &>.card-text-link__slogan {
            margin-top: 0px;
        }
    }
}

.article-thumb {
    text-align: center;
    width: 270px;
    display: block;
    margin-bottom: 17px;

    h3 {
        background: $white;
        margin: -5px 0 0;
        padding: 15px;
        min-height: 97px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.page--article-summary {
    font-size: 23px;
    border-bottom: 2px solid $gold;
    padding-bottom: 40px;
    margin-bottom: 40px;
}

.page--article-body {
    font-size: 19px;
    color: $dark-grey;
    letter-spacing: 0.2px;
    line-height: 29px;

    & h2 {
        font-size: 1.5rem;
    }

    & h3 {
        @extend .subtitle;
        margin-top: 35px;
        font-size: 0.9rem;
    }

    & blockquote {
        position: relative;
        font-weight: bold;

        &:after {
            @extend .gradient;
            left: -2em;
            border-left: 3px solid $gold;
        }
    }
}

.article-page__send-enquiry {
    margin-top: -60px;

    .card {
        position: relative;
        background: $white;
    }
}

@media (max-width: $breakpoint-small) {
    .page--article-inner {
        flex-direction: column;
        padding-top: 260px;
    }

    .page--article-main {
        margin-right: 0;
        padding-bottom: 20px;
        margin: 0 auto;
        width: 95%;
    }

    .page--article-sidebar {
        background: $white;
        padding: 0;
        margin-right: 0;

        .title--lines {
            margin: 25px 0;
        }

        .title--lines {
            span {
                background: $white;
            }

            &:before {
                display: none;
            }

            &:after {
                content: "";
                position: absolute;
                top: 6px;
                right: 25%;
                left: unset;
                border-top: 2px solid #33312b;
                z-index: 1;
                width: 50%;
            }
        }
    }

    .related-articles {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
    }

    .article-page__sidebar-send-enquiry {
        display: none;
    }

    .article-page__send-enquiry {
        margin-top: 0;
    }
}

@media (max-width: $breakpoint-xsmall) {
    .page--article-inner {
        padding-top: 170px;
    }

    .page--article-main {
        padding-left: 20px;
        padding-right: 20px;

        .page--article-title {
            font-size: 30px;
        }

        .page--article-summary {
            font-size: 19px;
        }
    }

    .page--article-sidebar {
        &>.article-thumb {
            width: 100%;
        }
    }

    .related-articles {
        flex-direction: column;
        align-items: center;
    }
}