.guides__top {
    background: $beige-bg;
}

.guides__top-banner-section {
    display: flex;
    margin-bottom: -300px;
    z-index: 0;
}

.guides__banner-wrap {
    position: relative;

    &:after {
        left: 0;
        width: 100%;
        background: linear-gradient(90deg,
                $beige-bg 1%,
                $transparent,
                $transparent),
            linear-gradient(0deg, $beige-bg 1%, $transparent);
    }
}

.guides__featured {
    position: relative;
    display: flex;
    justify-content: center;
    z-index: 2;
}

.guides__featured-image {
    margin-right: -50px;
}

.guides__featured-text {
    background: $white;
    text-align: center;
    max-width: 627px;
    padding: 35px 60px;
    position: relative;
    top: 50px;
    width: 608px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h2 {
        font-size: 38px;
        margin-bottom: 0;
        margin-top: 20px;
    }
}

.guides__featured-summary {
    &:after {
        margin: 40px auto 20px;
        max-width: 413px;
        width: 100%;
    }
}

.article-category {
    color: #ababa8;

    path {
        fill: #ababa8;
    }

    span {
        display: block;
        font-size: 10px;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 0.08em;
    }
}

.guides__top-articles {
    margin-top: 100px;
    padding: 40px 0;

    h2 {
        text-align: center;
        font-size: 30px;
        position: relative;
        margin-bottom: 60px;

        span {
            position: relative;
            z-index: 2;
        }

        &:before {
            content: "";
            width: 110px;
            height: 110px;
            border-radius: 110px;
            display: block;
            position: absolute;
            top: -30px;
            left: 50%;
            transform: translateX(-50%);
            background: $white;
            z-index: 1;
        }
    }
}

.article__thumb-mini-list {
    display: flex;
    justify-content: center;
    padding: 50px 0;
}

.article__thumb-mini {
    display: flex;
    max-width: 500px;
    margin: 0 30px;

    svg {
        display: inline-block;
        vertical-align: middle;
        width: 20px;
        height: 20px;
    }

    .article-category {
        span {
            display: inline-block;
        }
    }

    h3 {
        font-size: 22px;
        margin-top: 2px;
    }
}

.article__thumb-mini-image {
    margin-right: 20px;
    height: 95px;
    display: contents;
}

.article__thumb-mini-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 20px;
}

.guides__filter {
    display: flex;
    justify-content: center;
    padding: 0 5px;
    margin-bottom: 10px;
}

.guides__main {
    max-width: 1247px;
    margin: 0 auto;
}

.article__thumb {
    text-align: center;
    background: $white;
    box-shadow: 0 0 20px rgba(207, 198, 202, 0.3);
    padding-bottom: 70px;
    position: relative;

    .article-category {
        position: absolute;
        bottom: 30px;
        left: 0;
        right: 0;
    }

    h3 {
        font-size: 22px;
        margin-top: -10px;
        z-index: 1;
        position: relative;
        padding: 2rem 2rem 0;
    }

    svg {
        width: 20px;
        height: 20px;
    }
}

.guides__main-inner {
    padding: 10px 43px;
    display: flex;
    flex-wrap: wrap;

    .article__thumb {
        flex: 0 0 calc(33.333% - 20px);
        margin: 0 10px 30px;
    }
}

.guide.article__thumb-image {
    position: relative;
}

@media (max-width: $breakpoint-small) {
    .guides__top-banner-section {
        margin-bottom: -140px;
        padding-bottom: 0;

        &:after {
            background-image: linear-gradient(180deg,
                    rgba(247, 244, 240, 0) 24%,
                    #f7f4f0 99%),
                linear-gradient(270deg,
                    rgba(247, 244, 240, 0.8) 0%,
                    rgba(247, 244, 240, 0) 33%),
                linear-gradient(268deg,
                    rgba(247, 244, 240, 0) 39%,
                    #f7f4f0 100%);
        }
    }

    .guides__featured {
        flex-direction: column;
        align-items: center;
    }

    .guides__featured-image {
        margin-right: unset;
        margin-bottom: -150px;
    }

    .guides__main-inner .article__thumb {
        flex: 0 0 calc(50% - 20px);
    }

    .guides__featured-text {
        width: 80%;
    }

    .article__thumb-mini-list {
        flex-direction: column;
        padding-bottom: 0;
        align-items: center;

        &>* {
            margin: 10px;
        }
    }
}

@media (max-width: $breakpoint-xsmall) {
    .guides__top-banner-section {
        margin-bottom: 0;

        .circle-title {
            transform: translate(0%, 40%);
            margin-left: unset;
            padding: 0 50px;

            &:before {
                width: 150px;
                height: 150px;
            }
        }
    }

    .guides__featured-image {
        padding: 5px;
    }

    .guides__featured-text {
        width: 90%;
    }

    .guides__top-articles {
        padding-top: 0px;
    }

    .guides__main-inner {
        padding: 0;

        .article__thumb {
            flex: 0 0 calc(100% - 20px);
        }
    }

    .guide.article__thumb-image>img {
        width: 100%;
    }

    .article__thumb-mini {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .article__thumb-mini-list {
        padding-top: 0;
    }
}