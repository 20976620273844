.custom-page__banner {
  position: relative;

  @media (max-width: $breakpoint-small) {

    .banner--double-buttons {

      &>.banner--double-buttons__image {

        &:after {
          height: 125%;
          background: linear-gradient(white 0%, $transparent, $transparent 30%, white 65%)
        }
      }

      .banner--double-buttons__text {
        margin: 5px;
      }
    }
  }

}

@media (max-width: $breakpoint-xsmall) {
  .custom-page__banner {
    position: relative;

    .banner--double-buttons {

      &>.banner--double-buttons__image {
        height: 350px;

        &:after {
          height: 100%;
        }
      }

      .banner--double-buttons__text {
        margin: 5px;
      }
    }
  }

}



.custom-page__learn-more {
  position: absolute;
  bottom: 50px;
}