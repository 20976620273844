.da__cat-item {
    @extend .radio-group__label;
    width: 100%;
    margin: 0;
    font-family: $title-font;
    font-size: 18px;
    color: $black-2;
    font-weight: 500;
    text-align: center;
    padding: 25px 0;
    height: auto;

    .radio-group__label-text {
        font-size: 18px;
    }

    // having image changes the alignment of the button
    &.has-image {
        display: flex;
        flex-wrap: wrap;
        height: auto;
        padding: 0;

        .radio-group__label-text {
            @extend .radio-group__label;
            width: 100%;
            margin: 0;
            height: 75px;

            @media (max-width: $breakpoint-small) {
                height: 50px;
            }
        }

        &.active {
            &::after {
                content: none;
            }
            .radio-group__label-text {
                box-shadow: 0 2px 5px 0 rgba(91, 55, 48, 0.18);
                background-color: #e0dcbc;
                position: relative;

                &::after {
                    border-radius: 50%;
                    width: 16px;
                    height: 16px;
                    min-width: 16px;
                    background: #33312b;
                    padding: 3px;
                    content: url(/images/icons/check-gold.svg);
                    position: absolute;
                    top: -10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }
}
