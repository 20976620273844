.page--proposal-recipient {
    @extend .proposal-form-page;
    background-color: $beige-bg;
    padding: 20px;

    .proposal-recipient__wrapper {
        background: $white-bg;
        max-width: 980px;
        margin: 0 auto;
        border: 5px solid #ffffff;
        box-shadow: 0 4px 30px 0 rgba(207, 198, 202, 0.3);
        padding: 50px 80px;

        @media (max-width: $breakpoint-medium) {
            padding: 20px;
        }

        .subtitle {
            color: $black;
            font-size: 13px;
            letter-spacing: 2.99px;
            line-height: 18px;
            position: relative;
            width: fit-content;
            margin: 0 auto 30px;
            font-weight: 600;

            &:before {
                content: "";
                position: absolute;
                top: 9px;
                left: -60px;
                border-top: 2px solid #ddd7a5;
                width: 40px;
            }

            &:after {
                content: "";
                position: absolute;
                top: 9px;
                right: -60px;
                border-top: 2px solid #ddd7a5;
                width: 40px;
            }
        }

        .proposal-recipient__top-row {
            text-align: center;

            h1 {
                font-size: 38px;

                @media (max-width: $breakpoint-xsmall) {
                    font-size: 26px;
                    letter-spacing: -0.63px;
                    line-height: 31px;
                }
            }

            .description {
                font-style: italic;
                font-size: 19px;
                color: #4f4e4e;
                letter-spacing: 0.22px;
                line-height: 26px;
            }
        }

        .input-wrapper {
            max-width: 556px;
            margin: 50px auto;

            @media (max-width: $breakpoint-xsmall) {
                margin: 20px auto;
            }
        }

        .proposal-recipient__bottom-row {
            text-align: center;
            max-width: 556px;
            margin: 0 auto;
        }
    }
    + .bottom-button-wrapper {
        max-width: 980px;
        margin: 0 auto;
        padding: 20px;

        @media (max-width: $breakpoint-xsmall) {
            flex-direction: column;

            > .btn--secondary {
                margin-bottom: 10px;
            }
        }
    }
}
