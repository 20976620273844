.proposal-thankyou__container {
    @extend .proposal-landing__container;

    .bottom-row {
        text-align: center;
        margin-top: 90px;

        @media (max-width: $breakpoint-xsmall) {
            padding: 1rem;
        }
    }
}

.proposal-thankyou {
    @extend .proposal-landing;
    padding-bottom: 0rem;

    .circle-title__title {
        white-space: pre-line;
    }

    .mid-card {
        flex-direction: row;
        background-color: $white;
        padding: 2rem 0.5rem;
        margin: 0 auto;
        text-align: left;
        transform: translateY(50px);
        max-width: 909px;
        overflow: hidden;

        @media (max-width: $breakpoint-medium) {
            justify-content: flex-end;
        }

        @media (max-width: $breakpoint-xsmall) {
            flex-direction: column-reverse;
            padding: 1rem;
        }
    }

    .proposal-thankyou__image {
        object-fit: contain;
        position: relative;
        left: -10%;

        @media (max-width: $breakpoint-medium) {
            left: 9%;
        }

        @media (max-width: $breakpoint-xsmall) {
            left: -20%;
        }
    }

    .text-side {
        transform: translateX(-50px);
        padding: 2rem;

        &__title {
            font-weight: 600;
            font-size: 27px;
            color: $dark-grey;
            letter-spacing: -0.47px;
            line-height: 39px;
            margin: 0;
        }

        &__header {
            font-weight: 600;
            font-size: 32px;
            color: $gold;
            letter-spacing: -0.56px;
            line-height: 39px;
            margin: 0;
        }

        &__description {
            font-size: 17px;
            line-height: 22px;
            margin: 0;
        }

        @media (max-width: $breakpoint-medium) {
            transform: unset;
        }
    }

    .group-list__li {
        display: inline;
        width: 32%;
        white-space: nowrap;
        padding-left: 0;
        margin-right: 5px;

        .group-list__li-svg {
            margin-right: 5px;
        }
    }

    .text-side__button-wrapper {
        display: flex;
        justify-content: space-between;

        button {
            min-width: auto;
        }

        @media (max-width: $breakpoint-medium) {
            flex-direction: row;

            button {
                min-width: 190px;

                &:first-of-type {
                    margin-right: 5px;
                }
            }
        }

        @media (max-width: $breakpoint-xsmall) {
            flex-direction: column;

            button {
                min-width: 200px;

                &:first-of-type {
                    margin-right: 0;
                    margin-bottom: 10px;
                }
            }
        }
    }
}
