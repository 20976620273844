.share-page__main {
    background: $beige-bg;
}

.share-page__feature-section--first {
    padding-top: 250px;
    margin-top: -160px;
}

.share-page__feature-section--second {
    flex-direction: row-reverse;
}

.share-page__add-content-circle {
    margin: 30px auto 100px;
    max-width: 500px;
    width: 100%;
    padding: 0 50px;
    display: flex;
    align-items: center;

    & > .circle-title__title {
        line-height: 3.5rem;
        font-size: 2.8rem;
    }

    &:before {
        top: -15px;
        width: 164px;
        height: 164px;
    }
}

.share-page__start-creating {
    margin: 60px 0;
    padding: 20px;
    position: relative;

    .card-text-link {
        background: $white;
        position: relative;
        padding: 2rem 0.5rem;
    }

    &:before {
        background: $beige-bg;
        content: "";
        width: 100%;
        height: 75%;
        max-width: 1540px;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -30px;
        margin: 0 auto;
    }
}

@media (max-width: $breakpoint-small) {
    .share-page__add-content-circle {
        margin-bottom: 30px;
    }
}

@media (max-width: $breakpoint-xsmall) {
    .share-page__add-content-circle {
        & > .circle-title__title {
            line-height: 2.5rem;
            font-size: 2rem;
        }

        &:before {
            top: -25px;
        }
    }
}
