#header + #footer {
    display: none;
}

#footer {
    text-align: center;
    padding: 25px 0;
    color: $off-white-2;
}
.footer__links {
    list-style-type: none;
    margin: 10px 0;
    padding: 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    li {
        padding: 0 25px;
    }
    a {
        color: $off-white-2;
        font-size: 12px;
        text-transform: uppercase;
        font-weight: 700;

        &:hover {
            cursor: pointer;
        }
    }
}

.footer__social-media-icons {
    @extend .footer__links;
    align-items: center;
}

.footer__legal {
    @extend .footer__links;
    margin-top: 20px;
    li {
        padding: 0 5px;

        &:not(:first-child):before {
            content: "";
            display: inline-block;
            vertical-align: middle;
            width: 3px;
            height: 3px;
            border-radius: 3px;
            background: #a99090;
            margin-right: 10px;
            position: relative;
        }
    }

    a {
        text-transform: unset;
    }
}
