.proposal__outer-container {
    position: relative;
}

.proposal-form-page {
    .top-row {
        background: $dark-grey;
        text-align: center;
        padding-top: 30px;

        .subtitle {
            color: $white;
            font-size: 13px;
            letter-spacing: 2.99px;
            line-height: 18px;
            position: relative;
            width: fit-content;
            margin: 0 auto 30px;

            &:before {
                content: "";
                position: absolute;
                top: 9px;
                left: -60px;
                border-top: 2px solid #ddd7a5;
                width: 40px;
            }

            &:after {
                content: "";
                position: absolute;
                top: 9px;
                right: -60px;
                border-top: 2px solid #ddd7a5;
                width: 40px;
            }
        }
    }

    .step-container {
        padding: 20px 100px 80px;
        background-color: $beige-bg;
        background: url(/images/proposal-bg.jpg) 100% 0 no-repeat #f7f4f0;
        background-size: 100% auto;
        position: relative;
        min-height: calc(100vh - 326px);

        @media (max-width: $breakpoint-medium) {
            padding: 20px;
        }

        > h1 {
            font-size: 45px;
            color: $black-3;
            margin-bottom: 0;

            @media (max-width: $breakpoint-xsmall) {
                font-size: 27px;
            }
        }

        .step-intro{
            font-size: 22px;
            font-size: 1.375rem;
            font-weight: 300;
            font-style: italic;
            max-width: 760px;
            margin: 10px 0 60px;

            @media (max-width: $breakpoint-small) {
                font-size: 17px;
                margin-bottom: 30px;
            }
        }
    }

    .da-form__cats {
        list-style-type: none;
        justify-content: center;
        padding-left: 0;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(55px, 120px));
        grid-template-rows: 75px;
        margin-bottom: 0;
        grid-auto-flow: column;

        @media (max-width: $breakpoint-small) {
            grid-template-rows: 55px;
        }

        @media (max-width: $breakpoint-xsmall) {
            grid-template-columns: repeat(auto-fill, minmax(53px, 1fr));
            grid-template-rows: 55px;
            overflow-x: auto;
            grid-auto-flow: column;
            justify-content: start;
        }

        .ask-about {
            font-weight: 500;
            font-size: 16px;
            color: #cfc6ca;
            letter-spacing: 0.19px;
            line-height: 31px;
            font-style: italic;
            display: flex;
            align-items: center;
            justify-content: center;

            @media (max-width: $breakpoint-xsmall) {
                display: none;
            }
        }

        .add-category__button {
            display: flex;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            @media (max-width: $breakpoint-xsmall) {
                position: absolute;
                right: 10px;
                top: 145px;
                z-index: 1;
            }

            .gold-circle {
                background-color: $gold;
                padding: 20px;
                border-radius: 100%;
                height: 50px;
                width: 50px;
                display: flex;
                align-items: center;
                transform: translateX(-10px);

                @media (max-width: $breakpoint-medium) {
                    transform: none;
                }

                @media (max-width: $breakpoint-xsmall) {
                    padding: 15px;
                    height: 40px;
                    width: 40px;
                }
            }

            svg g {
                stroke: $black-3;
            }
        }
    }

    .da-form__cat {
        margin: 20px;
        font-weight: bold;
        cursor: pointer;
        background: $white;
        box-shadow: -1px 0 0 0 rgba(91, 55, 48, 0.1);
        margin: 0;
        margin-top: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: transform 0.2s;
        position: relative;

        @media (max-width: $breakpoint-xxsmall) {
            width: 55px;
        }

        > .da-form__remove-cat {
            display: none;
        }

        &.selected {
            transform: scaleY(1.1333);
            transform-origin: bottom;
            border-top: 4px solid $gold;
            box-shadow: -1px 0 0 0 rgba(91, 55, 48, 0.1);

            > .da-form__remove-cat {
                position: absolute;
                right: 10px;
                top: 5px;
                display: block;
                padding-left: 20px;
                padding-bottom: 20px;

                @media (max-width: $breakpoint-xsmall) {
                    right: 3px;
                    top: -3px;
                }
            }
        }
    }

    .da-form__cat-invalid {
        position: absolute;
        transform: translate(15px, 15px);
    }

    .da-cat-select,
    .da-cat-radio {
        margin-bottom: 50px;
        position: relative;
    }

    .step-form {
        background: $white-bg;
        box-shadow: 0 4px 30px 0 rgba(207, 198, 202, 0.3);
        padding: 10px 70px 50px;

        @media (max-width: $breakpoint-medium) {
            padding: 30px 50px;
        }

        @media (max-width: $breakpoint-medium) {
            padding: 20px;
        }
    }

    .proposal-form__group {
        position: absolute;
        right: 0;
        top: -40px;

        p.subtitle::before {
            content: "";
            display: inline-block;
            width: 11px;
            height: 11px;
            border-radius: 7.5px;
            background-color: $gold;
            margin-right: 5px;
            top: 1px;
            position: relative;
        }

        p.subtitle.invalid-group::before {
            background-color: $obd-red;
        }

        @media (max-width: $breakpoint-xsmall) {
            position: relative;
            top: -15px;

            .subtitle {
                padding-top: 10px;
            }
        }
    }

    .proposal-radio-group {
        position: relative;
    }

    .proposal-form__group-options {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 20px;

        @media (max-width: $breakpoint-medium) {
            grid-template-columns: repeat(3, 1fr);
        }

        @media (max-width: $breakpoint-xsmall) {
            display: block;
            .da__cat-item{
                margin-bottom: 10px;
                text-align: left;
                justify-content: start;
                padding: 21px 23px;

                .radio-group__label-text{
                    text-align: left;
                    font-size: 16px;
                }
            }
        }
    }
    .radio-group__main.grid{
        @media (max-width: $breakpoint-xsmall) {
            .proposal-form__group-options {
                display: grid;
                grid-template-columns: 1fr 1fr;
                .da__cat-item{
                    padding: 0;
                }
            }
        }
    }


    .bottom-button-wrapper {

    }
}

.button-wrapper{
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    background: $white;
    padding: 20px 0;
    z-index: 5;
    border-top: 1px solid #ededed;
    box-shadow: 0 0 20px rgba(0,0,0,.08);
    justify-content: space-between;

    @media (max-width: $breakpoint-small) {
      padding: 10px 0;
    }

    .container{
        display: flex;
        max-width: 1340px;

        @media (max-width: $breakpoint-small) {

            padding: 0 13.5px;

            button {
              width: auto;
              margin: 5px 0;
              min-width: 0;
              flex-grow: 0;
            }
          .btn--back{
            padding: 15px 12px;
            margin-right: 7px;
          }
          .btn--continue{
            margin-left: auto;
            padding-left: 10px;
            padding-right: 10px;
            min-width: 100px;

          }
          span{
            flex-grow: 1;
            display: flex;
            margin-right: 7px;
          }
          .btn--forward{
            flex-grow: 1;
            padding-left: 0;
            padding-right: 0;
          }

        }
    }
    .btn--continue{
        margin-left: auto;
    }

    .btn--back{
        margin-right: 5px;
        min-width: fit-content;
        padding: 15px 20px;

        .btn--back__svg {
            margin-right: 3px;
        }
    }

    .btn--forward .btn--forward__svg {
        margin-left: 3px;
    }

}

.proposal-question-title {
    position: relative;
    margin: 50px 0 30px;


    @media (max-width: $breakpoint-medium) {
        margin: 30px 0;
    }
    @media (max-width: $breakpoint-small) {
        margin: 0 0 15px;
    }

    &:before {
        content: "";
        top: 19px;
        left: 0;
        right: 0;
        border-top: 2px solid #33312b;
        display: inline-block;
    }

    > span {
        font-size: 24px;
        color: $black-3;
        letter-spacing: -0.94px;
        line-height: 1.2;
        background-color: $white;
        padding: 0 10px 0 0;
        width: fit-content;
        position: relative;
        font-weight: 600;

        @media (max-width: $breakpoint-medium) {
            font-size: 21px;
        }
    }

    & + p:not(.additional-notes__text){
        margin-top: -25px;
        margin-bottom: 30px;
    }

    &.mb-0 {
        margin-bottom: 0;
    }
}

@media(max-width: $breakpoint-small){
    .final-questions__wrapper{
        .proposal-question-title + p:not(.additional-notes__text){
            margin-top: 0;
        }
    }
}


.additional-notes__text {
    margin-top: 0;
    margin-bottom: 30px;
}
