.banner--vendor {
    text-align: center;
}

.vendor__about {
    display: flex;
    justify-content: center;
    padding: 1rem;
}

.vendor__about__text {
    max-width: 650px;
    position: relative;
    padding: 0rem 4rem;
}

.vendor__about__categories {
    display: flex;
    margin: 30px 0;
}

.vendor__about__list {
    padding: 40px;
    padding-bottom: 20px;
}

.vendor__video {
    position: relative;
    margin-bottom: -50px;
    z-index: 1;
    display: flex;
    width: 100%;
    padding: 0 20px;
    justify-content: center;

    & > .video-block > .video-block__image {
        height: 104px;
        width: 184px;
    }

    &--multiple {
        justify-content: space-evenly;
    }
}

.vendor__media {
    margin: 0;
}

.vendor-page__contact-form {
    display: flex;
    justify-content: center;
}

@media (min-width: $breakpoint-medium) {
    .vendor__about__list {
        min-width: 40%;
    }
}

@media (max-width: $breakpoint-small) {
    .vendor__video--multiple .video-block {
        width: 45%;

        & > .video-block__image {
            width: 100%;
            height: 163px;
        }
    }

    .vendor__about__list {
        min-width: 30%;
        padding: 50px 10px;
    }

    .vendor__media {
        margin-bottom: -100px;
        padding-bottom: 70px;
    }
}

@media (max-width: $breakpoint-xsmall) {
    .vendor__about {
        flex-direction: column-reverse;
    }

    .vendor__about__text {
        padding: 40px 15px;
    }

    .vendor__about__categories {
        flex-direction: column;
        align-items: center;

        & > :first-child {
            margin-bottom: 20px;
        }
    }

    .vendor__video {
        width: 100%;

        &--multiple {
            flex-direction: column;
        }

        .video-block {
            width: 100%;
            margin: 10px 0;

            & > .video-block__image {
                width: 100%;
                height: 200px;
            }
        }
    }

    .vendor-page__contact-form {
        margin: -40px 10px 0;
    }
}
