.gallery {
    background: $beige-bg;
    display: block;
    text-align: center;
    padding: 80px 0;
    position: relative;
    margin-bottom: -50px;

    &:before {
        content: "";
        display: block;
        background: #fff;
        width: 181px;
        height: 181px;
        border-radius: 221px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 80px;
    }
}

.gallery__about {
    position: relative;
}

.gallery__title {
    font-size: 2rem;
}

.gallery__main {
    columns: 6 200px;
    column-gap: 10px;
    position: relative;

    & > div {
        color: white;
        margin: 5px 0;
        display: block;
        width: 100%;
    }
}

.gallery__main.vendor-page {
    columns: 4 200px;
}

.gallery__main > .gallery__video-thumb {
    & > .video-thumb__preview {
        height: 168px;
    }
}
.image-360 > .image-360-wrap.gallery__image-360-thumb > iframe {
    pointer-events: none;
}

.gallery__link--all-photos {
    margin-top: 30px;
}

@media (max-width: $breakpoint-xsmall) {
    .gallery__main {
        margin: 0 7px;
    }
}
