.hamburger__container {
  display: inline-block;
  cursor: pointer;
}

.bar1, .bar2, .bar3 {
  width: 25px;
  height: 2px;
  background-color: #3f3f3f;
  margin: 5px 0;
  transition: 0.4s;
}

/* Rotate first bar */
.hamburger-toggle .bar1 {
  transform: rotate(-45deg) translate(-5px, 5px) ;
}

/* Fade out the second bar */
.hamburger-toggle .bar2 {
  opacity: 0;
}

/* Rotate last bar */
.hamburger-toggle .bar3 {
  transform: rotate(45deg) translate(-5px, -5px) ;
}

@media(min-width: $breakpoint-medium) {
  .hamburger__container {
      display: none;
  }
}