.video-block__container {
    position: relative;
}

.video-block {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    max-width: 494px;
    width: 100%;
    padding: 10px;
    background-color: #fff;
    position: relative;

    &:hover {
        cursor: pointer;
    }
}

.video-block__image {
    width: 100%;
    height: 164px;

    &.full-width {
        width: 100%;
    }
}

.image-360-wrap.video-block__image-360>iframe {
    pointer-events: none;
    height: 104px !important;
}

.video-block__description {
    max-width: 237px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 207px;
}

.video-block__text-subtitle {
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.08em;
}

.video-block__text-title {
    margin: 0;
}

.video-block__icon-container {
    background: $gold;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.vendor-thumb__play-icon {
    width: 13px;
    height: 13px;
    margin-left: 2px;
}

.vendor-thumb__360-logo-icon {
    margin-top: 3px;
    width: 23px;
    height: 23px;
}

@media (max-width: $breakpoint-small) {
    .video-block__description {
        margin: 10px 0;
    }

    .image-360-wrap.video-block__image-360>iframe {
        height: 165px !important;
    }
}

@media (max-width: $breakpoint-xsmall) {
    .video-block__image {
        width: 100%;
        height: 200px;
        margin-bottom: 10px;
    }
}