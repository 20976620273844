@mixin iconBlackCircle($WxH) {
    border-radius: 50%;
    width: $WxH;
    height: $WxH;
    min-width: $WxH;
    background: $black-3;
    padding: 3px;
}

@mixin gold-corner(
    $top: unset,
    $right: unset,
    $bottom: unset,
    $left: unset,
    $rotate: 0deg
) {
    position: relative;
    padding: 0rem 2rem;

    &:after {
        content: url(/images/gold-corner.png);
        position: absolute;
        top: $top;
        right: $right;
        bottom: $bottom;
        left: $left;
        transform: rotate($rotate);
    }
}

.gold-corner {
    &--tl {
        @include gold-corner($top: 0, $left: 0);
    }

    &--double {
        @include gold-corner;

        &:before {
            content: url(/images/gold-corner.png);
            position: absolute;
            transform: rotate(180deg);
        }

        // Top left bottom right
        &--tlbr {
            @extend .gold-corner--double;

            &:after {
                top: 0;
                left: 0;
            }

            &:before {
                bottom: 0;
                right: 0;
            }
        }
        // top right bottom left
        &--trbl {
            @extend .gold-corner--double;

            &:after {
                top: 0;
                right: 0;
                transform: rotate(90deg);
            }

            &:before {
                bottom: -3px;
                left: 0px;
                transform: rotate(270deg);
            }
        }
    }
}

@media (max-width: $breakpoint-xsmall) {
    .gold-corner--double--trbl {
        &:after {
            top: -7px;
            right: 3px;
        }
    }
}
