.proposal-category-modal__container {
    &.active::before {
        opacity: 0.95;
    }

    @media (max-width: $breakpoint-xsmall) {
        &.active {
            top: 70px;
            left: 0;
            right: 0;
            margin: 10px;
            position: fixed;
        }
    }

    .proposal-category-modal {
        background-color: $white-bg;
        padding: 50px;
        position: fixed;
        top: 55%;
        z-index: 100;

        transform: translateY(-50%);

        @media (max-width: $breakpoint-xsmall) {
            top: 65%;
            padding: 20px;
            position: absolute;
            transform: none;
            overflow: auto;
            max-height: calc(100vh - 90px);
        }

        .proposal-category-modal__top-row {
            text-align: center;

            h1 {
                font-size: 38px;

                @media (max-width: $breakpoint-xsmall) {
                    font-size: 26px;
                    letter-spacing: -0.63px;
                    line-height: 31px;
                }
            }

            .description {
                font-style: italic;
                font-size: 19px;
                color: #4f4e4e;
                letter-spacing: 0.22px;
                line-height: 26px;
            }
        }

        @media(max-width: $breakpoint-xsmall){
            padding-bottom: 100px;
        }
    }

    .proposal-category-modal__button--close {
        text-align: end;
        cursor: pointer;
    }

    .proposal-category-modal__cat-select {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 20px;
        justify-content: center;

        @media (max-width: $breakpoint-small) {
            justify-content: space-around;
            grid-template-columns: repeat(2, 1fr);
        }

        @media (max-width: $breakpoint-xsmall) {
            grid-template-columns: 1fr;
        }
    }
    .proposal-category-modal__cat-item {
        cursor: pointer;
        padding: 20px;

        box-shadow: 0 2px 5px 0 rgba(91, 55, 48, 0.18);

        display: flex;
        justify-content: center;
        align-items: flex-end;
        position: relative;

        @media (max-width: $breakpoint-medium) {
            height: 90px;
        }

        @media (max-width: $breakpoint-small) {
            background-size: cover;
            grid-template-columns: 1fr;
        }

        @media (max-width: $breakpoint-xsmall) {
            padding: 10px;
        }

        &:hover {
            .da-landing__cat-item-text-wrap {
                opacity: 0.4;
            }
        }

        &.unselected:hover {
            &::after {
                content: url(/images/icons/plus-gold--bg-black.svg);
                position: absolute;
                margin: auto;
            }
        }

        &.selected {
            background-color: $gold;
            &::after {
                content: url(/images/icons/close--gold.svg);
                position: absolute;
                top: 10px;
                right: 10px;
            }
        }
    }

    .proposal-category-modal__button-wrapper {
        text-align: center;
        margin-top: 30px;

        button:disabled,
        button[disabled] {
            opacity: 0.4;
            pointer-events: none;
        }
    }
}
