.venue-thumbs {
    background: $beige-bg;
    padding: 0;
    padding-bottom: 200px;
    margin-bottom: -200px;
    margin-top: 50px;
    position: relative;

    &:before {
        content: "";
        display: block;
        background: #fff;
        width: 181px;
        height: 181px;
        border-radius: 221px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: -60px;
    }
}

.venue-thumbs__main {
    display: flex;
    justify-content: space-between;
    max-width: 1191px;
    padding: 0 10px;
    margin-bottom: 20px;
}

.venue-thumbs__title-wrap {
    position: relative;
    display: flex;
    justify-content: center;
}

.venue-thumbs__title {
    position: absolute;
    top: -60px;
    font-size: 1.5rem;
}

.venue-thumb {
    display: flex;
    flex-direction: column;
    margin: 0 0 30px;
    flex: 0 0 calc(33.3333% - 15px);
    padding-bottom: 50px;
    position: relative;
    text-align: center;
    background: $white;
    overflow: hidden;

    &:nth-child(2) {
        margin: 0 20px 30px;
    }
}

.venue-thumb__image-wrap {
    @extend .gradient;

    &:after {
        height: 101%;
        background-image: linear-gradient($transparent, $white 90%);
    }
}

.venue-thumb__images {
    position: relative;
    margin-bottom: 15px;
    height: 280px;
    display: flex;
    justify-content: center;
}

.venue-thumb__image {
    height: 100%;
    width: 100%;
}

.venue-thumb__thumb-container {
    position: absolute;
    bottom: -20px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
}

.venue-thumb__thumb-nail {
    width: 100%;
    height: auto;
    margin: 5px;
    max-width: 96px;
    max-height: 73px;
}

.venue-thumb__photographer {
    display: flex;
    align-items: center;
    flex-direction: column;
    bottom: 0;
    width: 100%;
    position: absolute;
    margin-bottom: 10px;
}

.venue-thumb__photographer-thumb {
    margin-bottom: 10px;
}

.venue-thumb__photographer-text {
    @extend .subtitle;
    color: $black;
}

.venue-thumb__text {
    @extend .vendor-thumb__text;
    flex-direction: column;
    padding-bottom: 40px;
}

.venue-thumb__photographer-thumb {
    width: 30px;
    height: auto;
}

@media (max-width: $breakpoint-small) {
    .venue-thumb__images {
        height: 168px;
    }

    .venue-thumb__thumb-nail {
        max-width: 60px;
    }
}

@media (max-width: $breakpoint-xsmall) {
    .venue-thumb__images {
        height: 255px;
    }

    .venue-thumbs__main {
        overflow: scroll;
        justify-content: unset;
        display: flex;
        flex-direction: row;
    }

    .venue-thumb {
        flex-basis: 80%;
        margin: 10px;

        &:nth-child(2) {
            margin: 10px;
        }
    }

    .venue-thumb__thumb-nail {
        max-width: 96px;
    }
}
