.text-input {
    width: 100%;
}

.text-input__input {
    @extend h1;
    background: #faf8f5;
    box-shadow: inset 0 2px 7px 0 rgba(91, 55, 48, 0.1);
    border: none;
    font-size: 15px;
    padding-left: 20px;
    width: 100%;
    letter-spacing: 0;
    height: 56px;
    font-family: $body-font;

    &::placeholder {
        font-style: italic;
        font-weight: 400;
    }

    &:focus {
        outline: none;
    }

    @media (max-width: $breakpoint-small) {
        font-size: 12px;
        padding-left: 12px;
    }
}

.radio-group__main {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.radio-group__label {
    background: #faf8f5;
    box-shadow: 0 2px 5px 0 rgba(91, 55, 48, 0.18);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 23%;
    min-width: 90px;
    height: 56px;
    margin: 10px 0;
    margin-right: 2%;
    transition: background-color 0.3s ease-in-out;

    &.active {
        box-shadow: 0 2px 5px 0 rgba(91, 55, 48, 0.18);
        background-color: #e0dcbc;
        position: relative;

        &::after {
            @include iconBlackCircle(16px);
            content: url(/images/icons/check-gold.svg);
            position: absolute;
            top: -10px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &:hover {
        cursor: pointer;
    }
}

.radio-group__input {
    display: none;

    &.active {
        display: block;
    }
}

.text-input__text-wrap {
    width: 100%;
}

.contact-form__input-icon-wrapper {
    display: flex;
    position: relative;
    margin-bottom: 10px;
}

.text-input__icon {
    background: #ffffff;
    border: 1px solid #f2eee4;
    height: 56px;
    display: flex;
    align-items: center;
    width: 68px;
    justify-content: center;
}

.radio-group__label-text {
    @extend h2;
    font-size: 0.8rem;
}

.check-box-group__main {
    @extend .radio-group__main;
}

.dmy__main {
    width: 100%;
}

.dmy__main-selects {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.dmy-select {
    @extend .radio-group__label-text;
    position: relative;
    line-height: 1.3;
    padding: 17px;
    width: 45%;
    min-width: 150px;
    height: 56px;
    margin: 5px 0;
    background: #faf8f5;
    border: none;
    box-shadow: inset 0 2px 7px 0 rgba(91, 55, 48, 0.1);

    &:focus {
        outline: none;
    }

    &:hover {
        cursor: pointer;
    }

    &:after {
        width: 20px;
        height: 20px;
        background-size: 8px auto;
        border: 0;
        margin-top: -7px;
        transform: rotate(90deg);
        transition: transform 0.2s ease-in-out;
        content: " ";
        display: block;
        position: absolute;
        top: 50%;
        right: 15px;
        background: url(/images/icons/arrow-right.svg) 50% no-repeat;
    }

    & > .dmy-options {
        @extend h2;
        position: absolute;
        background: $white;
        width: 100%;
        left: 0;
        top: 56px;
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 200px;

        & > .dmy-option {
            padding: 5px 5px 5px 19px;

            &:hover {
                cursor: pointer;
                background: $blue-bg;
            }
        }
    }
}

.dmy-select__title {
    margin: 0;
    font-weight: normal;
}

.dmy-options {
    position: absolute;
    background: $white;
    opacity: 0;
    visibility: hidden;

    &.active {
        opacity: 1;
        visibility: visible;
        z-index: 99;
    }

    .dmy-option {
        @extend h2;
    }
}

.dmy-select__main--day,
.dmy-select__main--year {
    width: 25%;
}

.dmy-select__main-option {
}

.dmy__main-checkbox {
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    & input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
}

.dmy-checkbox {
    display: flex;
    align-items: center;
}

.dmy-checkbox__text {
    display: inline;
    margin-left: 10px;
    font-size: 0.9rem;
}

.dmy-checkbox__icon {
    width: 16px;
    height: 16px;
    display: inline-block;
    border-radius: 50%;
    background: #faf8f5;
    border: 1px solid $gold;

    &.active {
        @include iconBlackCircle(16px);
        content: url(/images/icons/check-gold.svg);
    }
}

@media (max-width: $breakpoint-medium) {
    .radio-group__label {
        width: 46%;
    }

    .dmy-select {
        width: 100%;
    }
}

@media (max-width: $breakpoint-xsmall) {
    .radio-group__label {
        min-width: 80px;
    }
}
