.expandable-text__button-container {
    display: flex;
    justify-content: center;
}

.expandable-text__title {
    font-size: 2rem;
}
.expandable-text__button {
    text-align: center;

    &:focus {
        outline: none;
    }

    &:hover {
        color: $black;
    }
}

@media (max-width: $breakpoint-xsmall) {
    .expandable-text__title {
        text-align: center;
    }
}
