/* The switch - the box around the slider */
.small-toggle {
    position: relative;
    display: inline-block;
    width: 60px;
    min-width: 60px;
    height: 37px;

    /* Hide default HTML checkbox */
    input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    /* The slider */
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $beige;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }

    .slider:before {
        position: absolute;
        content: url("/images/icons/close.svg");
        height: 22px;
        width: 22px;
        left: 6px;
        bottom: 6px;
        background-color: $white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        box-shadow: 0 2px 5px 0 rgba(91, 55, 48, 0.18);
        text-align: center;
        padding-bottom: 2px;
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(90%);
        -ms-transform: translateX(90%);
        transform: translateX(90%);
        content: url("/images/icons/check-black.svg");
    }
}
