/* The switch - the box around the slider */
.large-toggle {
  position: relative;
  display: inline-block;
  width: 189px;
  min-width: 189px;
  height: 60px;

  /* Hide default HTML checkbox */
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $beige;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    font-family: $body-font;
    font-size: 11px;
    color: $black;
    letter-spacing: 0.63px;
    text-align: center;
    text-transform: uppercase;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .slider__box {
    position: absolute;
    height: 87%;
    width: 50%;
    left: 4px;
    top: 4px;
    background-color: #fff;
    transition: 0.4s;
    box-shadow: 0 2px 5px 0 rgba(91, 55, 48, 0.18);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
  }

  input:checked + .slider > .slider__box {
    -webkit-transform: translateX(90%);
    -ms-transform: translateX(90%);
    transform: translateX(90%);
  }
}
