.register-interest {
    max-width: 850px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 30px 40px;
    background: $white;
    margin: 0 auto;
}

.register-interest__title {
    font-size: 2rem;
}

.register-interest__form {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 360px;

    *> {
        width: 100%;
    }

}

.register-interest__btn--submit {
    width: 100%;
}

.register-interest__info {
    max-width: 360px;
    text-align: center;
    font-size: 0.8rem;
    margin-top: 20px;
}

@media (max-width: $breakpoint-xsmall) {
    .register-interest__title {
        font-size: 1.3rem;
    }

    .register-interest__form {
        width: 90%;
    }
}