.image-360 {
  width: 100%;

  &>.image-360-wrap {
    width: 100%;
    position: relative;

    &>iframe {
      height: 75vh !important;
    }
  }
}

.mbo__image-360>.image-360>.image-360-wrap:after {
  position: absolute;
  content: "";
  left: 0;
  width: 100%;
  height: 15%;
  bottom: 0;
  background-image: linear-gradient(180deg, rgba(253, 253, 253, 0) 0%, #FDFDFD 90%);
  pointer-events: none;
}

@media(max-width: $breakpoint-medium) {
  .image-360>.image-360-wrap>iframe {
    // height: 65vh !important;
  }

}