.specialist-cta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $white;
    max-height: 120px;
    height: 114px;
    position: relative;
    width: 95%;

    & > .specialist-cta__block--left {
        display: flex;
    }
}

.specialist-cta__button--back {
    border: none;
    background: $white;
    display: flex;
    align-items: center;
    margin-right: 5px;
    & > svg {
        height: 20px;
        width: 12px;
    }
}

.specialist-cta__info {
    display: flex;
    align-items: center;

    & > img.specialist-cta__info-thumb {
        width: 60px;
        height: 60px;
    }

    & > .specialist-cta__info-text {
        margin-left: 10px;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        height: 100%;
        justify-content: space-evenly;
        max-width: 140px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
}

.specialist-cta__info-name {
    margin: 0;
}

.specialist-cta__info-category {
    line-height: 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.specialist-cta__info-category--indiv {
    display: flex;
    align-items: center;
    margin-right: 3px;
    margin-bottom: 3px;

    & > span {
        margin-top: 2px;
        margin-left: 3px;
    }
}

.specialist-cta__block--left--small {
    display: none;
}

@media (max-width: $breakpoint-small) {
    .specialist-cta {
        flex-direction: column;
        padding: 15px;

        .specialist-cta__info-thumb {
            display: none;
        }

        .specialist-cta__block--center {
            font-size: 1.5rem;
        }

        .specialist-cta__block--right {
            display: none;
        }
    }

    .specialist-cta__button--back {
        position: absolute;
        left: 10px;
        top: 45%;
    }

    .specialist-cta__info {
        background: $white;
        position: relative;
        z-index: 1;
        padding: 0 10px;

        & > .specialist-cta__info-text {
            margin-left: 0;
            position: relative;
            background: $white;
            padding: 0 10px;
            max-width: unset;
        }

        &:before {
            content: "";
            position: absolute;
            top: 12px;
            left: -5%;
            border-top: 2px solid $gold;
            width: 110%;
        }
    }

    .specialist-cta__info-category {
        display: none;
    }

    .specialist-cta__block--left--small {
        display: flex;
    }
}
