.loader-container {
    width: 100vw;
    height: calc(100vh - 90px);
    position: absolute;
    top: 89px;
    z-index: 999;
    background: white;
    visibility: visible;
    opacity: 1;
    transition: visibility 0.5s ease-in, opacity 1s ease-out;

    &.inactive {
        visibility: hidden;
        opacity: 0;
    }
}

.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
}
.loader {
  color: #000;
  font-size: 11px;
  text-indent: -99999em;
  margin: 55px auto;
  position: relative;

  width: 10em;
  height: 10em;
  box-shadow: inset 0 0 0 1em;
  -webkit-transform: translateZ(0) translateY(-100%);
  -ms-transform: translateZ(0) translateY(-100%);
  transform: translateZ(0) translateY(-100%);
  top: 50%;
}
.loader:before,
.loader:after {
  position: absolute;
  content: '';
}
.loader:before {
  width: 5.2em;
  height: 10.2em;
  background: #fff;
  border-radius: 10.2em 0 0 10.2em;
  top: -0.1em;
  left: -0.1em;
  -webkit-transform-origin: 5.1em 5.1em;
  transform-origin: 5.1em 5.1em;
  -webkit-animation: load2 2s infinite ease 1.5s;
  animation: load2 2s infinite ease 1.5s;
}
.loader:after {
  width: 5.2em;
  height: 10.2em;
  background: #fff;
  border-radius: 0 10.2em 10.2em 0;
  top: -0.1em;
  left: 4.9em;
  -webkit-transform-origin: 0.1em 5.1em;
  transform-origin: 0.1em 5.1em;
  -webkit-animation: load2 2s infinite ease;
  animation: load2 2s infinite ease;
}
@-webkit-keyframes load2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}