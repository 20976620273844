.vendor-gallery__main {
    & > .gallery {
        padding: 0 20px 80px 20px;
        background: $white;
        position: relative;
        margin-bottom: 0;

        & > .gallery__about,
        & > .gallery__main {
            z-index: 1;
        }

        &:after {
            content: "";
            background: $beige-bg;
            width: 100%;
            height: 90%;
            left: 0;
            top: 10%;
            position: absolute;
        }
    }
}
