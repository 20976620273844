.card-venue-info {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
}

.card-venue-info__subtitle {
    display: block;
    width: fit-content;
}

.card-venue-info__text {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    padding: 0 15px;
    & * {
        margin: 0;
    }
}

.card-venue-info__thumb > svg {
    width: 45px;
    height: 45px;
}

.card-venue-info__description {
    line-height: 1;
    font-size: 0.9rem;
    font-style: italic;
    letter-spacing: 0.17px;
    margin: 8px 0;
    min-height: 25px;
}

@media (max-width: $breakpoint-xsmall) {
    .card-venue-info {
        flex-direction: column;
        text-align: center;
        width: 70%;
    }

    .card-venue-info__text {
        align-items: center;
    }
}
