.pagination {
    display: flex;
    justify-content: center;
}

.pagination__icon {
    border: none;
}

li[class^="rc-pagination-"],
[class=" rc-pagination-disabled"],
[class=" rc-pagination-next"],
[class=" rc-pagination-prev"] {
    border: none !important;
    @extend h1;
}

.rc-pagination-disabled {
    color: $black;
    opacity: 0.4;
}

.rc-pagination-item.rc-pagination-item-active {
    background-color: unset;
    color: $black-3;
    opacity: 1;
    &:focus {
        outline: none;
    }

    &:hover > a {
        color: $black-3;
    }

    a {
        color: $black-3;
    }
}
