.page--proposal-final-questions {
    @extend .proposal-form-page;
    background-color: $beige-bg;
    padding: 20px;

    .proposal-form__group-options {
        grid-template-columns: repeat(auto-fit, minmax(25%, 1fr));
     }

    .final-questions__wrapper {
        background: $white-bg;
        max-width: 980px;
        margin: 0 auto;
        border: 5px solid #ffffff;
        box-shadow: 0 4px 30px 0 rgba(207, 198, 202, 0.3);
        padding: 50px 80px;

        @media (max-width: $breakpoint-medium) {
            padding: 20px;
        }
    }

    .top-row {
        background: $white;
        color: $black-2;

        .subtitle {
            color: inherit;
        }

        h1 {
            font-size: 38px;
        }
    }

    .finance-options {
        text-align: center;
        padding: 2rem;
        border: 8px solid #e8e5ca;

        @media(max-width: $breakpoint-small){
            margin-bottom: 20px;
        }

        h2 {
            font-size: 24px;
            color: #33312b;
            letter-spacing: -0.94px;
            line-height: 25px;
            font-weight: bold;
        }

        > p {
            max-width: 600px;
            margin: 0 auto;
            margin-bottom: 30px;
        }

        .finance-options__buttons-wrapper {
            display: grid;
            grid-template-columns: repeat(2, 200px);
            grid-gap: 20px;
            justify-content: center;

            @media (max-width: $breakpoint-small) {
                grid-template-columns: 1fr;
            }
        }
    }

    .form__label {
        font-size: 16px;
        color: $dark-grey;
        text-align: center;
        font-weight: 500;
        font-family: $title-font;
        letter-spacing: 0;
        line-height: 21px;
    }

    .form__question-wrapper {
        margin-top: 20px;
        .text-input__text-wrap {
            margin-top: 10px;
        }

        .text-input, .dmy {
            min-height: auto;
        }
    }

    .proposal-final__button-wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(25%, 32%));
        grid-gap: 20px;
        margin-top: 10px;

        @media (max-width: $breakpoint-small) {
            grid-template-columns: 1fr;
        }
    }

    .field-inspiration__wrapper {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -5px 10px;

        .field-inspiration__item {
            background: $black;
            border-radius: 3px;
            font-family: $body-font;
            font-size: 15px;
            color: #ebe6dd;
            font-style: italic;
            margin: 5px;
            padding: 10px 35px 10px 15px;
            font-weight: 500;
            max-width: 262px;
            overflow: hidden;
            text-overflow: ellipsis;
            position: relative;

            span {
                cursor: pointer;
                position: absolute;
                right: 11px;
                top: 25%;
            }
        }
    }

    .add-inspiration__wrapper {
        display: flex;

        .text-input.contact-form__container {
            min-height: auto;
        }

        button {
            border: 1px solid #f2eee4;
            height: 56px;
            display: flex;
            align-items: center;
            width: 68px;
            justify-content: center;
            background: $white;
            box-shadow: 0 2px 7px 0 rgba(91, 55, 48, 0.18);
            cursor: pointer;
            text-transform: uppercase;
            font-size: 11px;
        }
    }
}

.proposal-final__submit-btn-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .btn--secondary {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 5px 0;
    }

    .edit-svg {
        margin-right: 5px;
    }

    @media (max-width: $breakpoint-small) {
        text-align: center;
        flex-direction: column;
    }
}
