.finance-options__text-wall {
    background: $beige-bg;
    padding: 210px 50px;
    margin: -120px auto;
}

.finance-options__register-options {
    padding: 20px;
}

@media (max-width: $breakpoint-xsmall) {
    .finance-options__text-wall {
        padding: 200px 50px 50px;
        margin: -130px auto;
    }

    .finance-options__register-options {
        padding-top: 80px;
    }
}
