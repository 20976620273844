.section--text-image{
  background: $beige-bg;
  padding: 50px 0;
  font-size: 18px;
  font-style: italic;


  &.padding_top{
    padding-top: 150px;
    margin-top: -100px;
  }

  img{
    width: 100%;
    height: auto !important;
  }

  @media (min-width: $breakpoint-small) {
    .container--large{
      display: flex;
    }

    .section__image{
      flex: 0 0 350px;
    }

    .section__text{
      padding-right: 50px;
    }
  }

  @media (max-width: $breakpoint-small) {
    padding-left: 20px;
    padding-right: 20px;
  }

}

.section--steps{
  text-align: center;
  padding: 30px;

  h2{
    font-size: 28px;
  }

  .step{
    max-width: 500px;
    margin: 60px auto;
  }
  .step-no{
    display: inline-block;
    border: 1px solid $black-3;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    font-size: 22px;
    font-weight: 700;
    line-height: 1;
    position: relative;
    top: -3px;
    margin-right: 10px;
  }
}

.section--faqs{
  background: $beige-bg;
  padding: 50px 0;

  h2{
    text-align: center;
    font-size: 28px;
  }

  h3{
    margin: 0;
    background: url(/images/icons/chevron-down-solid.svg) right center no-repeat;
    cursor: pointer;
    background-size: 20px auto;
    padding-right: 50px;
  }

  .faq__text{
    padding: 10px 0;
  }

  .faq{
    padding: 15px 0;


    &:not(:last-child){
      border-bottom: 1px solid $off-white-2;
    }

    p{
      margin: 0;
      padding: 10px 0;
    }
  }

  @media (max-width: $breakpoint-small) {
    padding-left: 20px;
    padding-right: 20px;
  }

}