.page--home {
    overflow-x: hidden;
}

.homepage-articles {
    background: $beige-bg;
    text-align: center;
    padding: 100px 0;
    margin-bottom: -90px;
    padding-bottom: 135px;

    .circle-title {
        margin: 0 auto;
    }

    .circle-title__title {
        font-size: 30px;
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;

        &.line-under {
            &:after {
                margin-top: 25px;
                width: 131px;
            }
        }
    }
}

.homepage-articles-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    z-index: 2;
    margin-bottom: 21px;

    .article-thumb {
        width: 302px;

        &>.article-thumb__img {
            position: relative;
            @extend .gradient--bottom-white-light;
        }

        img {
            width: 100%;
        }
    }
}

.homepage-articles__sign-up {
    z-index: 2;
    width: 90%;
    margin: 0 auto;

    .homepage__sign-up {
        align-items: center;

        &>.card-text-link__slogan {
            max-width: 600px;
        }
    }
}

.homepage-cta {
    display: flex;
    justify-content: space-around;
    position: relative;
    margin: 20px;

    &>div {
        max-width: 600px;
    }

    .home-page__join-now-button {
        position: relative;
        z-index: 2;
    }
}

@media (max-width: $breakpoint-medium) {
    .homepage-articles-list .article-thumb .article-thumb__img:after {
        background-image: none;
    }
}

@media (min-width: $breakpoint-xsmall) {
    .homepage-articles-list .article-thumb {
        min-width: 25%;
        max-width: 302px;
        width: 33%;
        padding: 1rem;

        h3 {
            min-height: 120px;
        }
    }
}

@media (max-width: $breakpoint-xsmall) {
    .homepage-cta {
        flex-wrap: wrap;
        margin: 30px auto;
        width: 90%;

        &>div {
            margin: 0px 20px;
            padding-top: 40px;
        }

        .homepage-articles-list .article-thumb {
            width: 80%;
        }
    }
}