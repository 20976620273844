.telephone.active {
  position: fixed;
  z-index: 6;
  top: 25%;
  left: 0;
  right: 0;
  padding: 0;

  @media (max-width: $breakpoint-small) {
    top: 15%;
    margin: 0 10px;
  }

  .telephone__modal {
    background: $white;
    padding: 3rem;
    position: relative;

    .btn {
      max-width: 230px;
      margin: 0 auto;
    }

    @media (max-width: $breakpoint-small) {
      padding: 1rem;
    }
  }

  &:before {
    position: relative;
    width: 100%;
    height: 100%;
    background: $white;
    top: 0;
    left: 0;
    content: '';
    z-index: 1;
  }

  &:after {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: $black-3;
    top: 0;
    left: 0;
    content: '';
    z-index: -1;
  }
}

.tel-why {
  position: absolute;
  color: $dark-grey;
  font-size: 12px;
  text-decoration: underline;
  right: 15px;
  top: 10px;
  font-weight: 400;
  cursor: pointer;
}

.telephone__wrap {
  display: flex;
  justify-content: center;

}

.telephone__title {
  font-family: $title-font;
  font-size: 23px;
  color: $black-3;
  letter-spacing: -0.58px;
}

.telephone__summary {
  font-family: $body-font;
  font-size: 17px;
  color: $dark-grey;
  letter-spacing: 0.2px;
  line-height: 24px;
  font-style: italic;

  p:last-child {
    font-weight: bold;
  }
}

.telephone-button--close {
  position: absolute;
  right: 15px;
  top: 15px;

  &>svg {
    width: 14px;
    height: 14px;
  }

  &:hover {
    cursor: pointer;
  }
}

@media (max-width: $breakpoint-xsmall) {
  .telephone.active .telephone__main {
    padding: 2rem;
  }
}