.edit-form__container {
    background-color: $white;
    padding: 3rem 4rem;
    color: $dark-grey;
    box-shadow: 0 4px 30px 0 rgba(207,198,202,0.30);

    @media (max-width: $breakpoint-xsmall) {
        padding: 1.5rem 1rem 5rem;
        position: relative;
    }

    .top-row__wrapper {
        display: flex;
        justify-content: space-between;
        margin-bottom: 2rem;
    }

    .edit-form__title {
        font-family: $title-font;
        font-size: 30px;
        letter-spacing: -1px;
        line-height: 37px;

        @media (max-width: $breakpoint-xsmall) {
            margin: 0 auto;
            font-size: 24px;
        }
    }

    .edit-form__form {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px 50px;
        margin-bottom: 20px;

        @media (max-width: $breakpoint-xsmall) {
            grid-template-columns: 1fr;
            text-align: center;
        }
    }

    .edit-form__label {
        font-size: 15px;
        letter-spacing: 0.18px;
        line-height: 31px;
        font-style: italic;
    }

    .edit-form__field-value {
        font-family: $title-font;
        font-size: 23px;
        letter-spacing: -0.42px;

        @media (max-width: $breakpoint-xsmall) {
            font-size: 18px;
        }
    }

    .contact-form__container {
        min-height: 70px;
    }

    &.change-password__form {
        .edit-form__form {
            display: block;
        }

        .change-password {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 20px 50px;

            @media (max-width: $breakpoint-xsmall) {
                grid-template-columns: 1fr;
            }
        }
    }
}

.edit__button {
    border: 2px solid $dark-grey;
    font-size: 11px;
    letter-spacing: 0.7px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 37px;
    color: $dark-grey;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 600;
    padding: 5px 10px;

    svg{
        margin-right: 5px;
    }

    @media (max-width: $breakpoint-xsmall) {
        position: absolute;
        bottom: 35px;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 100px;
    }
}
