.modal-black-overlay {
    visibility: hidden;
    opacity: 0;
    position: absolute;

    &.active {
        position: relative;
        visibility: visible;
        opacity: 1;
        z-index: 99;
        display: flex;
        justify-content: center;

        .mbo__main {
            color: $white;
            position: fixed;
            top: 55%;
            z-index: 100;
            display: flex;
            justify-content: space-between;
            left: 0;
            right: 0;
            transform: translateY(-50%);
            height: 90%;
        }

        .mbo__arrow {
            display: flex;
            align-items: center;
            width: 60px;
        }

        .mbo__arrow--right,
        .mbo__arrow--left {
            color: $white;
            display: flex;

            &>svg {
                width: 13px;
                height: 13px;
            }

            &:hover {
                cursor: pointer;
            }
        }

        .mbo__arrow--left {
            justify-content: flex-end;
        }

        .mbo__arrow--right {
            justify-content: flex-start;
        }

        .mbo__content {
            position: relative;
            display: flex;
            align-items: center;
            width: 70vw;
            justify-content: center;
        }

        .mbo__content-media {
            width: 100%;
            display: flex;
            justify-content: center;

            &>.mbo__image-360 {
                width: 100%;
            }
        }

        .mbo__image-360-bottom {
            display: flex;
            align-items: center;
            background: $white;
            width: 100%;
            padding: 1rem;
            margin-top: -5px;

            h4 {
                margin: 0 10px;
                font-size: 13px;
                color: $dark-grey;
                letter-spacing: 0.15px;
                font-style: italic;
            }
        }

        .mbo__content-button--close {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            right: -24px;
            top: 80px;
            width: 45px;
            height: 45px;
            z-index: 1;
            background: $white;
            box-shadow: 0 4px 10px 0 rgba(207, 198, 202, 0.3);

            &:hover {
                cursor: pointer;
            }

            &>svg {
                width: 14px;
                height: 14px;
            }
        }
    }

    &.active::before {
        position: fixed;
        background: $black-3;
        top: 0;
        content: "";
        left: 0;
        height: 100vh;
        width: 100vw;
    }
}

.mbo__content-media--image {
    max-height: 85vh;
    width: 100%;
    object-fit: contain;
}

.mbo__content-media-wrapper-video {
    width: 640px;
    height: 360px;
}

.mbo__content-media--video {
    max-width: 640px;
    max-height: 360px;
}

@media (max-width: $breakpoint-small) {
    .modal-black-overlay.active {
        .mbo__content-button--close {
            top: 100px;
        }

        .mbo__arrow--left,
        .mbo__arrow--right {
            justify-content: center;
        }
    }

    .mbo__content-media-wrapper-video {
        width: 420px;
        height: 240px;
    }
}

@media (max-width: $breakpoint-xsmall) {
    .modal-black-overlay.active {
        .mbo__content {
            width: 80vw;
        }

        .mbo__content-button--close {
            right: -15px;
            top: 80px;
            width: 30px;
            height: 30px;
        }

        .mbo__arrow {
            width: 30px;
        }

        .vid-block-360 {
            .mbo__content {
                width: 90vw;
                margin: 0 auto;
            }

            &>.mbo__arrow {
                display: none;
            }
        }

        .mbo__content-media-wrapper-video {
            width: 300px;
            height: 170px;
        }

    }
}