.contact-form-view {
    padding: 60px;
    background: $white;
    position: relative;
    margin: 10px;
    max-width: 1171px;
    width: 100%;
}

.contact-form-view__preview-title {
    font-size: 30px;
    letter-spacing: -1px;
    line-height: 37px;
    text-align: center;
}

.contact-form-view__button,
.contact-form__button--submit {
    text-align: center;
    margin-top: 20px;
}

.contact-form {
    & > *:not(:last-child) {
        border-bottom: 1px solid $gold;
        padding: 20px 0;
    }
}

.contact-form__container {
    display: flex;
    min-height: 100px;
}

.contact-form__group {
    width: 215px;
    min-width: 215px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &.invalid > .contact-form__group-name {
        position: relative;

        &::after {
            content: url(/images/icons/attention-large.svg);
            position: absolute;
            right: -25px;
            top: 3px;
        }
    }
}

.contact-form__group-options {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: flex-start;
}

.contact-form__group-name {
    margin: 0 0 3px 0;
    width: fit-content;
}

.contact-form__radio-button {
    opacity: 0;
    position: fixed;
    width: 0;
}

.contact-form__invalid {
    opacity: 0;

    &.active {
        color: $obd-red;
        opacity: 1;
        transition: opacity 0.3s ease-in-out;
        text-align: center;
        margin: 10px;
    }
}

.contact-form__note {
    margin-bottom: 5px;
}

@media (max-width: $breakpoint-small) {
    .contact-form__container {
        flex-direction: column;
    }

    .contact-form__group {
        margin-bottom: 10px;
    }
}

@media (max-width: $breakpoint-xsmall) {
    .contact-form-view {
        margin: 13px 0;
        padding: 30px;
    }

    .contact-form__group-options {
        justify-content: space-between;
    }
}
