.banner {
    background-size: cover;
    background-position: center center;
    position: relative;
    @extend .gradient;

    &--centered {
        text-align: center;
        height: 620px;
        max-width: 1400px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        z-index: 1;
        width: 90%;

        .banner__text {
            max-width: 700px;
            margin: auto auto 65px;
        }

        h1 {
            font-size: 70px;
            margin-bottom: 20px;
        }

        p {
            font-size: 23px;
            font-style: italic;
            margin: 15px 0;
        }

        &:after {
            background: linear-gradient(0deg, white 20%, $transparent);
            z-index: -1;
        }
    }

    &--double {
        padding-top: 120px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: right top;
        min-height: 700px;
        z-index: 3;

        .circle-title {
            margin-left: 50%;
            transform: translateX(-81%);
        }

        .banner--double-text-wrap {
            display: flex;
            justify-content: center;
            position: absolute;
            bottom: -30px;
            left: 0;
            padding: 0 20px;
        }

        .banner--double-text {
            background: $white;
            text-align: center;
            padding: 40px;
            position: relative;

            h2 {
                font-size: 2rem;
                margin: 0 0 20px;
            }

            .text {
                font-size: 19px;
                font-style: italic;
            }

            &:first-child {
                max-width: 767px;
                padding-left: 60px;
                padding-right: 60px;

                .text {
                    margin-bottom: 28px;
                }

                &::before {
                    background-image: url(/images/card-flourishes-right.png);
                    content: "";
                    position: absolute;
                    right: -75px;
                    bottom: -75px;
                    width: 264px;
                    height: 222px;
                    z-index: 1;
                    background-repeat: no-repeat;
                }

                &::after {
                    background-image: url(/images/card-flourishes-left.png);
                    content: "";
                    position: absolute;
                    left: -75px;
                    bottom: -75px;
                    width: 264px;
                    height: 222px;
                    background-repeat: no-repeat;
                    z-index: 1;
                }
            }

            &:last-child {
                flex: 0 0 414px;
                background: #fdfdfd;
                position: relative;
                top: 40px;

                h2 {
                    font-size: 30px;
                    margin-bottom: 10px;
                }

                .text {
                    font-size: 17px;
                    margin-bottom: 28px;
                }

                &::after {
                    background-image: url(/images/card-flourishes-ancilliary.png);
                    position: absolute;
                    content: "";
                    left: 75px;
                    bottom: -15px;
                    width: 148px;
                    height: 125px;
                }
            }
            .banner--double--arrow-link {
                margin-top: 20px;
            }

        }

        &:after {
            z-index: -1;
            background: linear-gradient(90deg,
                    #f8f4f1,
                    #f8f4f1,
                    $transparent 62%,
                    $transparent,
                    #f8f4f1),
                linear-gradient(0deg, #f8f4f1 10%, $transparent);
        }
    }

    &--minimal {
        background-size: auto 100%;
        background-position: left center;
        background-repeat: no-repeat;
        display: flex;
        background-color: $white;
        max-width: 1320px;
        margin: 0 auto;
        box-shadow: 0 4px 30px rgba(207, 198, 202, 0.7);
        width: 90%;
        z-index: 1;

        .banner__text-wrap {
            margin-left: auto;
            flex: 0 0 50%;
            text-align: center;
            padding: 20px 10px 80px;
            position: relative;
            z-index: 1;
        }

        h1 {
            font-size: 3.5rem;
            line-height: 1;
            margin-bottom: 0;

            &:after {
                width: 81px;
                margin-top: 50px;
            }
        }

        .banner__text {
            font-size: 23px;
            font-style: italic;
        }

        &:after {
            background: linear-gradient(90deg, $transparent, white 65%);
            z-index: -1;
        }
    }

    &--vendor {
        box-shadow: 0 4px 30px rgba(207, 198, 202, 0.7);
        width: 95%;
        margin: 0 auto;

        .banner--vendor__main-wrap {
            position: relative;
            z-index: 1;
            padding: 50px;
            line-height: 25px;
        }

        .banner__logo {
            width: 125px;
            height: auto;
            padding: 15px;
            background: $white;
        }

        .banner__title {
            font-size: 38px;
            margin: 10px 0;
        }

        .banner__vendor-cats {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            margin: 10px 0;
        }

        .banner__vendor-cats-indiv {
            display: flex;
            align-items: center;
            margin: 0 5px;

            &>svg {
                margin-right: 5px;
                width: 20px;
                height: 20px;
            }
        }

        &:after {
            background: linear-gradient(0deg, white 30%, $transparent);
        }

        &:before {
            content: "";
            width: 50%;
            height: 75%;
            background: $beige-bg;
            position: absolute;
            bottom: -30px;
            right: -30px;
            z-index: -1;
        }
    }

    &--double-buttons {
        display: flex;
        background: $white;
        position: relative;
        z-index: 1;
        overflow: hidden;

        .banner--double-buttons__image {
            width: 60%;
            margin-right: -100px;
            position: relative;
            height: 482px;

            &>img {
                min-height: 482px;
            }

            &:after {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(90deg,
                        white 0%,
                        $transparent,
                        $transparent 60%,
                        white 85%);
            }
        }

        .banner--double-buttons__text-wrap {
            display: flex;
            align-items: center;
            flex-direction: column;
            text-align: center;
            width: 50%;
            justify-content: center;
            z-index: 1;
        }


        .banner--double-buttons__title {
            font-size: 2.5rem;
            padding: 0 55px;
        }

        .banner__button-wrap {
            display: flex;
            flex-wrap: nowrap;

            &> :first-child {
                margin-right: 5px;
            }
        }

        .banner--double-buttons__text {
            margin: 30px;
        }

        &:after {
            @extend .gradient;
            outline: 2px solid #f2eeda;
            outline-offset: -10px;
        }
    }
}

.banner__subtitle {
    padding: 0 2rem;
}

.banner__slogan {
    font-weight: 700;
    padding: 0 2rem;
}

.banner__actions {
    margin-top: 40px;
}

@media (max-width: $breakpoint-medium) {
    .banner--double {
        margin-bottom: 120px;

        .banner--double-text-wrap {
            bottom: -60px;

            &>.banner--double-text:first-child {
                padding: 30px 20px;
                font-size: 0.5rem;
            }

            &>.banner--double-text:last-child {
                padding: 40px 30px;
                flex: 0 0 300px;
            }
        }
    }

    .banner--double-buttons {
        margin: 0 10px;

        .banner--double-buttons__title {
            font-size: 2rem;
            padding: 0 40px;
        }
    }
}

@media (max-width: $breakpoint-small) {
    .banner--vendor {
        &:after {
            right: -20px;
        }
    }

    .banner--minimal {
        flex-direction: column;
        justify-content: flex-end;
        height: 553px;

        .banner__text-wrap {
            padding: 0 20px;

            h1 {
                font-size: 3rem;
            }
        }

        &:after {
            background: linear-gradient($transparent, white 65%);
            z-index: -1;
        }
    }

    .banner--double {
        min-height: 480px;
        margin-bottom: 0px;
        padding-bottom: 700px;

        .banner--double-text-wrap {
            bottom: 60px;
            flex-wrap: wrap;

            &>.banner--double-text:first-child {
                padding: 30px 20px;
                font-size: 0.5rem;
            }

            &>.banner--double-text:last-child {
                padding: 30px 20px;
                flex: 0 0 auto;
                width: 100%;
            }

            .banner--double--arrow-link {
                margin-top: 20px;
            }
        }

        &:after {
            background: linear-gradient(180deg,
                    rgba(247, 244, 240, 0) 24%,
                    #f7f4f0 99%),
                linear-gradient(268deg,
                    rgba(247, 244, 240, 0) 39%,
                    #f7f4f0 100%),
                linear-gradient(270deg,
                    rgba(247, 244, 240, 0.8) 0%,
                    rgba(247, 244, 240, 0) 33%),
                linear-gradient(0deg, #f8f4f1 65%, $transparent);
        }
    }

    .banner--double-buttons {
        flex-direction: column;
        align-items: center;

        .banner--double-buttons__text-wrap {
            width: 90%;
            margin-bottom: 50px;
        }

        .banner--double-buttons__image {
            width: 100%;
            margin-bottom: -150px;
            margin-right: unset;

            &:after {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(white 0%,
                        $transparent,
                        $transparent 60%,
                        white 85%);
            }
        }
    }
}

@media (max-width: $breakpoint-xsmall) {
    .banner {
        &--centered {
            h1 {
                font-size: 3rem;
            }
        }
    }

    .banner--vendor {
        &:before {
            // TODO: recheck after fixing gallery banner
            right: -2.5%;
            width: 100vw;
            overflow: hidden;
        }
    }

    .banner--minimal {
        height: 409px;

        .banner__text-wrap {
            h1 {
                font-size: 1.8rem;

                &:after {
                    margin-top: 20px;
                }
            }

            .banner__text {
                font-size: 1rem;
            }
        }
    }

    .banner--double {
        background-size: inherit;

        padding-bottom: 1000px;

        .circle-title {
            transform: translate(-50%, 120%);
        }

        .banner--double-text {
            &:first-child {
                &::before {
                    right: unset;
                    bottom: -180px;
                    background-size: 163px 137px;
                    left: -80px;
                }

                &::after {
                    left: -75px;
                    bottom: unset;
                    top: -70px;
                    background-size: 163px 137px;
                }
            }

            &:last-child {
                &::after {
                    bottom: unset;
                    left: unset;
                    top: -70px;
                    right: -65px;
                }
            }
        }

        .banner--double-text-wrap {
            bottom: 60px;

            .banner--double-text:last-child {
                width: 100%;
                flex: 0 0 auto;
            }
        }

        &:after {
            background: linear-gradient(90deg,
                    #f8f4f1,
                    $transparent 20%,
                    $transparent 90%,
                    #f8f4f1),
                linear-gradient(0deg, #f8f4f1 53%, $transparent);
        }
    }

    .banner--double-buttons {

        & &__title {
            padding: 0 20px;
        }

        .banner__button-wrap {
            flex-wrap: wrap;
            justify-content: center;

            &>* {
                margin: 5px 0;
                width: 80%;
            }
        }

        .banner--double-buttons__image {
            height: 250px;

            &>img {
                min-height: 250px;
            }

            &:after {
                background: linear-gradient($transparent,
                        $transparent 60%,
                        white 100%);
            }
        }
    }
}