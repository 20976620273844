.group-list__title {
    text-align: start;
    font-size: 12px;

    & > span {
        background: $white;
        z-index: 1;
    }

    &:before {
        content: "";
        position: absolute;
        top: 6px;
        left: 0;
        right: 0;
        border-top: 1px solid $gold;
    }
}

.group-list__ul {
    padding: 0;
}

.group-list__li {
    list-style-type: none;
    display: inline-flex;
    align-items: center;
    margin: 10px 0;
    width: 50%;
    padding-left: 10px;
}

.group-list__li-text {
    font-size: 16px;
    margin: 0;
}

.group-list__li-main {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 0.5rem;
}

.group-list__capacity {
    font-size: 1rem;
    font-weight: 500;
    margin: 0;
    line-height: 1;
}

.group-list__li-svg {
    @include iconBlackCircle(13px);
}

@media (min-width: $breakpoint-xsmall) and (max-width: $breakpoint-medium) {
    .group-list__li {
        display: flex;
        width: 100%;
    }
}
