.vendor-thumb {
    margin: 0 10px 30px;
    flex: 0 0 calc(33.3333% - 20px);
    padding-bottom: 50px;
    position: relative;
    text-align: center;
    background: $white;
    overflow: hidden;
}

.vendor-thumb__360-icon {
    position: absolute;
    right: -10px;
    top: -20px;
    background: white;
    border-radius: 255px;
    padding: 10px;
    display: flex;
    width: 60px;
    height: 60px;
    align-items: flex-end;
    z-index: 1;
}

.vendor-thumb__image-wrapper {
    position: relative;

    @extend .gradient--bottom-white;
}

.vendor-thumb__text {
    display: flex;
    justify-content: center;
    padding: 5px;
}

.vendor-thumb__category {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    position: absolute;
    bottom: 10px;
    width: 100%;
}

.vendor-thumb__category-indiv {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 3px;
}

.vendor-thumb__category-text {
    color: $black;
    margin: 0 4px;
}

.vendor-thumb__category-svg {
    width: 20px;
    height: auto;
}

@media (max-width: $breakpoint-medium) {
    .vendor-thumb__category-svg {
        width: 16px;
    }
}

@media (max-width: $breakpoint-xsmall) {
    .vendor-thumb {
        flex-basis: 95%;
        display: flex;
        height: 130px;
        margin: 0 0 10px;
        padding-bottom: 0;

        .vendor-thumb__image-wrapper {
            width: 90%;

            &:after {
                background-image: linear-gradient(
                    90deg,
                    $transparent 80%,
                    $white 100%
                );
            }
        }

        & > .vendor-thumb__360-icon {
            right: 0;
            bottom: 0;
            top: unset;
            background: $transparent;
        }

        & > .vendor-thumb__image {
            width: 30%;
            height: 100%;
        }

        & > .vendor-thumb__text {
            padding: 0.5rem 1rem;
            width: 100%;
            text-align: left;
            flex-direction: column;

            & > .vendor-thumb__title {
                font-size: 17px;
                margin: 0.5rem 0;
            }

            & > .vendor-thumb__category {
                flex-direction: column;
                justify-content: start;
                align-items: flex-start;
                position: relative;
                bottom: 0;

                & > .vendor-thumb__category-text {
                    padding-left: 10px;
                    font-size: 12px;
                }
            }
        }
    }

    .vendor-thumb__category-indiv {
        justify-content: center;
        flex-direction: row;
        margin: 3px 0;
    }
}
