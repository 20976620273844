.blogs__filter {
    position: relative;
    padding: 0;
    margin: 0 53px;
}

.blog-list__main {
    position: relative;
    margin: 0 auto;

    &:after {
        content: "";
        background: $beige-bg;
        width: 100%;
        height: 70%;
        position: absolute;
        z-index: -1;
        top: -60px;
    }
}

.blog-list {
    position: relative;
}

.blogs__filter__button--back {
    position: absolute;
    top: 40%;
    left: 15px;

    &>svg {
        width: 15px;
        height: 15px;
    }
}

.blogs__filter-main {
    @extend .guides__filter;

    flex-direction: column;
    align-items: center;

    margin-top: 20px;
    background: $white;
    padding: 20px 0;
}

.blogs-list__title {
    width: fit-content;
    position: relative;

    h2 {
        background: white;
        position: relative;
        margin: 0;
        padding: 0 20px;

        letter-spacing: 2.99px;
    }

    &:before {
        content: "";
        position: absolute;
        top: 6px;
        left: -20%;
        border-top: 2px solid $gold;
        width: 140%;
    }
}

.blog-list__main-inner {
    @extend .guides__main-inner;
}

@media (max-width: $breakpoint-small) {
    .blogs__filter {
        margin: 10px;
    }

    .blog-list__main-inner {
        padding: 20px 0px;
    }
}

@media (max-width: $breakpoint-xsmall) {
    .page--blog-list {
        .blogs-filter__list-item--label {
            display: none;
        }
    }
}