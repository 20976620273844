.card {
    display: flex;
    flex-direction: column;
    text-align: center;
    max-width: 850px;
    padding: 4rem 0.5rem;

    &.white-bg {
        background: $white;
    }

    &.card--small {
        max-width: 580px;
    }
}

.card-text-link__title {
    font-size: 2.5rem;
    margin: 0;
}

.card-text-link__slogan {
    @extend .feature-section__text;
    margin: 20px 0;

    .card-text-link__slogan-text--1 {
        margin-top: 20px;
        font-weight: bold;
    }
}

.card-double-btn__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    margin: 0 auto;

    @media (max-width: $breakpoint-xsmall) {
        grid-template-columns: 1fr;
        margin: 0;

        .btn {
            width: 100%;
        }
    }
}

.card-text-btm__slogan {
    margin-top: 1rem;
    font-family: $body-font;
    font-style: italic;
    font-size: 15px;
    color: $dark-grey;
    letter-spacing: 0.18px;
    line-height: 31px;
}

@media (max-width: $breakpoint-small) {
    .card {
        padding: 50px 20px;
    }

    .card-text-link__title {
        font-size: 1.5rem;
    }
}
