.dashboard-wedding-site {
    .top-row {
        display: grid;
        grid-template-columns: 1fr 1.5fr;
        grid-gap: 30px;
        justify-content: center;

        > div {
            width: 100%;
            max-width: unset;
            padding: {
                bottom: 2rem;
                left: 2rem;
                right: 2rem;
            }
        }

        @media (max-width: $breakpoint-medium) {
            grid-template-columns: 1fr;

            .publish-website {
                grid-row-start: 1;
            }
        }
    }

    .form-row {
        padding: 92px 85px;
        background-color: $white;
        margin-top: 30px;

        @media (max-width: $breakpoint-medium) {
            padding: 62px;
        }

        @media (max-width: $breakpoint-xsmall) {
            padding: 20px;
        }
    }
}

.wedding-site__form {
    background: $white;

    .title {
        font-family: $title-font;
        font-size: 19px;
        letter-spacing: -0.5px;
    }

    .description {
        font-family: Lato-LightItalic;
        font-size: 15px;
        letter-spacing: 0.18px;
        line-height: 20px;
        margin-top: 10px;
    }
}

.wedding-site__form-top {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
    color: $dark-grey;

    @media (max-width: $breakpoint-medium) {
        grid-template-columns: 1fr;
    }

    & > div {
        padding: 26px;
    }

    .countdown-timer {
        display: flex;
        justify-content: space-between;
        .small-toggle {
            align-self: center;
        }
    }
}

.wedding-site__form-bottom {
    & > div {
        margin: 2rem 0;
    }

    .public-website-address__top-row,
    .website-private__container {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }

    .website-private__container {
        @media (max-width: $breakpoint-xsmall) {
            flex-direction: column;
            align-items: start;
        }
    }

    .website-private__text-wrapper {
        @media (max-width: $breakpoint-xsmall) {
            margin-bottom: 15px;
        }
    }

    .text-input__input {
        font-family: $body-font;
        margin-top: 12px;
        font-style: italic;
        font-size: 16px;
    }
}
