.proposal-validation-modal__container {
    @extend .proposal-category-modal__container;

    .proposal-validation-modal {
        padding-top: 30px;
    }

    .proposal-validation-modal__top-row {
        text-align: center;
        .title {
            font-size: 23px;
            letter-spacing: -0.58px;
        }

        .description {
            font-size: 15px;
            color: #4f4e4e;
            letter-spacing: 0.18px;
            font-style: italic;
        }
    }

    .da-landing__cat-item-text-wrap {
        flex-direction: row;
        margin-bottom: 10px;
        font-size: 15px;
        justify-content: space-between;

        &:before {
            content: "";
            top: 13px;
            left: 0;
            right: 0;
            border-top: 1px solid #bbbdbf;
            display: inline-block;
            position: absolute;
        }
    }

    .text-wrap__inner {
        display: flex;
        align-items: center;
    }

    .proposal-validation-modal__cat-item-icon {
        width: 23px;
        height: 23px;
        padding-right: 5px;
        position: relative;
        background-color: $white;
    }

    .step-title {
        background-color: white;
        position: relative;
        padding-right: 10px;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }

    .remove-button {
        background-color: $white;
        position: relative;
        font-family: $body-font;
        font-size: 12px;
        color: #4f4e4e;
        text-decoration: underline;
        padding-left: 10px;
        cursor: pointer;
    }
    .proposal-validation-modal__button-wrapper{
        text-align: center;
        padding: 25px 0 0;
    }
}
